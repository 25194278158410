import React from 'react';
import ellipsis from 'text-ellipsis';
import { useStateValue } from '../../state';
import { Image, Message, Icon } from 'semantic-ui-react';

/**
 * Función encargada de devolver los mensajes de las bitacoras para
 * su uso en un componente. 
 * @param {String} type - Tipo de bitacora
 * @param {[]} bitacoras - Arreglo de bitacoras a mostrar
 * @param {Boolean} list - ¿Muestra todas las bitacoras o no?
 * @returns {[]} Cartas con cada bitacora
 */
const bitacorasCards = (type, bitacoras, list) => {

    // eslint-disable-next-line
    const [{ appFocusRight }, dispatch] = useStateValue();

    const onClick = (bitacora) => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'bitacoraDetail'
        });
        dispatch({
            type: 'setBitacoraActual',
            newBitacoraActual: bitacora
        });
    }

    let cartasBitacoras = [];

    switch (type) {
        case 'actividades':
            if (bitacoras.actividades !== []) {
                bitacoras.actividades.forEach(element => {
                    cartasBitacoras.push(
                        <div
                            key={Math.random()}
                            className='bitacoras-list'
                            onClick={() => { onClick(element) }}>
                            <Message
                                floating
                                key={Math.random()}
                                color='blue'
                                className='pa0 br18 ma3 mt0-5 bsn mb0'
                            >
                                <div>
                                    <Image
                                        src='/img/bitacoras/actividad.svg'
                                        className="bitacora-img pa2"
                                        inline
                                    />
                                    <span className="b f7">
                                        { ellipsis(element.name, 15) }
                                    </span>
                                    <div 
                                    className="fr flex items-center pr0-5 mt2px">
                                        <span className="f7 mr3 lh-36">
                                            {element.timeLapse}
                                        </span>
                                        {(list === true) ? (
                                            <Icon
                                                color="blue"
                                                name="chevron right" 
                                                className='lh-solid'/>
                                        ) : null}
                                    </div>
                                </div>
                            </Message>
                        </div>
                    );
                });
            }
            break;
        case 'producto':
            if (bitacoras.producto !== []) {
                bitacoras.producto.forEach(element => {
                    cartasBitacoras.push(
                        <div
                            key={Math.random()}
                            className='bitacoras-list'
                            onClick={() => { onClick(element) }}>
                            <Message
                                floating
                                color='teal'
                                className='pa0 br18 ma3 mt0-5 bsn mb0'
                            >
                                <div>
                                    <Image
                                        src='/img/bitacoras/producto.svg'
                                        className="bitacora-img pa2"
                                        inline
                                    />
                                    <span className="b f7">
                                        { ellipsis(element.name, 15) + element.quantity + " "}
                                    </span>
                                    <div 
                                    className="fr flex items-center pr0-5 mt2px">
                                        <span className="f7 mr3 lh-36">
                                            {element.timeLapse}
                                        </span>
                                        {(list === true) ? (
                                            <Icon
                                                color="teal"
                                                name="chevron right" 
                                                className='lh-solid'/>
                                        ) : null}
                                    </div>
                                </div>
                            </Message>
                        </div>
                    );
                });
            }
            break;
        case 'sintoma':
            if (bitacoras.sintoma !== []) {
                bitacoras.sintoma.forEach(element => {
                    cartasBitacoras.push(
                        <div
                            key={Math.random()}
                            className='bitacoras-list'
                            onClick={() => { onClick(element) }}>
                            <Message
                                floating
                                color='yellow'
                                className='pa0 br18 ma3 mt0-5 bsn mb0'
                            >
                                <div>
                                    <Image
                                        src='/img/bitacoras/sintomas.svg'
                                        className="bitacora-img pa2"
                                        inline
                                    />
                                    <span className="b f7">
                                        { ellipsis(element.name, 15) }
                                    </span>
                                    <div 
                                    className="fr flex items-center pr0-5 mt2px">
                                        <span className="f7 mr3 lh-36">
                                            {element.timeLapse}
                                        </span>
                                        {(list === true) ? (
                                            <Icon
                                                color="yellow"
                                                name="chevron right"
                                                className='lh-solid' />
                                        ) : null}
                                    </div>
                                </div>
                            </Message>
                        </div>
                    );
                });
            }
            break;
        case 'enfermedad':
            if (bitacoras.enfermedad !== []) {
                bitacoras.enfermedad.forEach(element => {
                    cartasBitacoras.push(
                        <div
                            key={Math.random()}
                            className='bitacoras-list'
                            onClick={() => { onClick(element) }}>
                            <Message
                                floating
                                color='red'
                                className='pa0 br18 ma3 mt0-5 bsn mb0'
                            >
                                <div>
                                    <Image
                                        src='/img/bitacoras/enfermedad.svg'
                                        className="bitacora-img pa2"
                                        inline
                                    />
                                    <span className="b f7">
                                        { ellipsis(element.name, 15) }
                                    </span>
                                    <div 
                                    className="fr flex items-center pr0-5 mt2px">
                                        <span className="f7 mr3 lh-36">
                                            {element.timeLapse}
                                        </span>
                                        {(list === true) ? (
                                            <Icon
                                                color="red"
                                                name="chevron right"
                                                className='lh-solid' />
                                        ) : null}
                                    </div>
                                </div>
                            </Message>
                        </div>
                    );
                });
            }
            break;
        case 'cosecha':
            if (bitacoras.cosecha !== []) {
                bitacoras.cosecha.forEach(element => {
                    cartasBitacoras.push(
                        <div
                            key={Math.random()}
                            className='bitacoras-list'
                            onClick={() => { onClick(element) }}>
                            <Message
                                floating
                                color='orange'
                                className='pa0 br18 ma3 mt0-5 bsn mb0'
                            >
                                <div>
                                    <Image
                                        src='/img/bitacoras/cosecha.svg'
                                        className="bitacora-img pa2"
                                        inline
                                    />
                                    <span className="b f7">
                                        { ellipsis(element.name, 15) + " " + element.quantity}
                                    </span>
                                    <div 
                                    className="fr flex items-center pr0-5 mt2px">
                                        <span className="f7 mr3 lh-36">
                                            {element.timeLapse}
                                        </span>
                                        {(list === true) ? (
                                            <Icon
                                                color="orange"
                                                name="chevron right"
                                                className='lh-solid' />
                                        ) : null}
                                    </div>
                                </div>
                            </Message>
                        </div>
                    );
                });
            }
            break;
        default:
            break;
    }

    return cartasBitacoras;
}

export default bitacorasCards;