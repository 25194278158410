import ls from 'local-storage';
import useFetch from 'use-http';
import { useStateValue } from '../../state';
import NewsfeedComponent from './NewsfeedComponent';
import { ERROR_GLOBAL_UNDEFINED} from '../common/consts';
import React, { useState, useEffect, useRef } from 'react';

const NewsfeedContainer = props => {

    const [newsfeed, setNewsfeed] = useState();
    const [errors, setErrors] = useState();

    const [{ cultivoActual }, dispatch] = useStateValue();
    
    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});

    const getNewsfeed = async () => {
        let temporalCultivo = props.cultivo ? props.cultivo : cultivoActual
        if (temporalCultivo !== null) {
            const dataNewsfeed = await request.get('/news_feed_cultivos/'+temporalCultivo.id+'?units=si');
            if (dataNewsfeed === undefined) {
                setErrors(ERROR_GLOBAL_UNDEFINED);
            } else {
                setNewsfeed(dataNewsfeed);
            } 
        } else {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        }
    }

    const onClickReturn = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'newsfeed'
        });
    }

    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) return
      mounted.current= true
      getNewsfeed();
    });

    return (
        <NewsfeedComponent 
        errors={errors}
        newsfeed={newsfeed} 
        onClickReturn={onClickReturn}/>
    )
}

export default NewsfeedContainer;