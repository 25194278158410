import React from 'react';
import { Image } from 'semantic-ui-react';

const MenuComponent  = props => {

    return(
        <div className="menu-container">
            <div className='title'>
                <h1 className='charcoal-grey'>Inteligencia de Negocios</h1>
            </div>
            <div className='grid-parent'>
                {/* CTA */}
                <div>
                    <h3>Todo el análisis de los cultivos que supervisas en un solo lugar</h3>
                    <p>{props.user.name}</p>
                    <p>
                        Consulta, exporta, digitaliza y descarga información importante con las siguientes opciones. 
                    </p>
                    <Image src='/img/inteligencia/menuHeader.svg'></Image>
                </div>
                {/* Reporte de registros */}
                <div>
                    <Image src='/img/inteligencia/reporte.svg' className='self-baseline'></Image>
                    <div>
                        <h2>Reporte de bitácora</h2>
                        <p>
                            Consulta y exporta las diferentes bitácoras de cada uno de los cultivos.
                        </p>
                    </div>
                    <Image src='/img/inteligencia/green-button.svg' className='action-btn' as='button' onClick={props.selectReport()}></Image>
                </div>
                {/* Digitalización de análisis */}
                <div>
                    <Image src='/img/inteligencia/analisis.svg' className='self-baseline'></Image>   
                    <div>
                    <h2>Digitalización de documentos</h2>
                        <p>
                            Digitaliza todos tus análisis y planes agrológicos por cultivo en el mismo lugar.
                        </p>
                    </div>
                    <Image src='/img/inteligencia/green-button.svg' className='action-btn' as='button' onClick={props.selectAnalysis()}></Image>
                </div>
                {/* Plan agrológico */}
                {/* <div>
                    <Image src='/img/inteligencia/plan.svg' className='self-baseline'></Image>
                    <div>
                        <h2>Plan agrológico</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                        </p>
                    </div>
                    <Image src='/img/inteligencia/green-button.svg' className='action-btn'></Image>
                </div> */}
            </div>
        </div>
    );
}

export default MenuComponent;