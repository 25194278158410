
import React, {useState} from 'react';
import { useStateValue } from '../../state';
import BitacoraComponent from './BitacoraComponent';
import { ERROR_GLOBAL_UNDEFINED} from '../common/consts';
import categorizeBitacoras from './categorizeBitacoras';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es')

const BitacoraContainer = props => {

    // eslint-disable-next-line
    const [{cultivoActual}, dispatch] = useStateValue();
    const [errors, setErrors] = useState();
    
    let bitacoras = {
        actividades: [],
        producto: [],
        enfermedad: [],
        sintoma: [],
        cosecha: []
    };
    
    if (props.bitacora) {
        //Se corta el array en el 3er indice porque es la cantidad de bitacoras que se muestra en el newsfeed
        let previewBitacoras = props.bitacora.slice(0,3);
        bitacoras = categorizeBitacoras(previewBitacoras);
    } else {
        setErrors(ERROR_GLOBAL_UNDEFINED);
    }

    const onClick = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'bitacorasList'
        });
    }
    
    return(
        <BitacoraComponent 
        errors={errors}
        onClick={onClick}
        sintoma={bitacoras.sintoma}
        cosecha={bitacoras.cosecha}
        producto={bitacoras.producto}
        enfermedad={bitacoras.enfermedad}
        actividades={bitacoras.actividades}/>
    );

}

export default BitacoraContainer;