/**
 * Estructura de las constantes => <TIPO>_<COMPONENTE>_<NOMBRE> = <VALOR>
 */

 //MESSAGES

    //ERRORS
    export const ERROR_LOGIN_INVALID = 'Contraseña o correo electronico invalido, intentalo de nuevo';
    export const ERROR_GLOBAL_UNDEFINED = 'Un error inesperado ha ocurrido, por favor intentalo más tarde';
    export const ERROR_GLOBAL_PASSWORD_MATCH = 'Las contraseñas no son iguales, asegurate de que coincidan';
    export const ERROR_RESET_PASSWORD_TOKEN = 'Esta operación no es válda, intentalo con un token nuevo';
    export const ERROR_TOKEN = 'El token no funciona'
    export const ERROR_FORMS_EMTPY = 'Tienes que llenar todos los datos del formulario'

    //MIN-MAX
    export const MIN_FORMS_PASSWORD_LENGHT = 'La longitud minima de la contraseña debe de ser de 6 caracteres';
        //NOTA: Esta constante se usa en todas las formas de registro, no solo en un componente

//REGEX
export const REGEX_EMAIL_VALIDATION = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@” + “[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
