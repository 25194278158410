import React from 'react';
import { useStateValue } from '../../state';
import GeneralComponent from './GeneralComponent';

const GeneralContainer = props => {

    // eslint-disable-next-line
    const [{ }, dispatch] = useStateValue();

    const clickReturnPrediosHandler = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: null
        });
        dispatch({
            type: 'setAppFocusLeft',
            newAppFocusLeft: 'predios'
        });
    }

    return (
        <GeneralComponent
        onClickReturnPredios={clickReturnPrediosHandler}/>
    );

}

export default GeneralContainer;
