import React from 'react';

import { Loader, Modal, Header, Icon } from 'semantic-ui-react';

const AcceptUserInvitationComponent = props => {
    return(
        <div>
        {
            (props.load === true) ? (
                <Loader 
                active />
            )
            : null
        }
        <Modal
            open={props.sended}
            basic
            size='small'>
                <div className='modal-header'>
                    <Header 
                    as='h1' 
                    color='green'
                    icon={<Icon
                            name='handshake'
                            className='tj'/>}
                    textAlign='center' 
                    content='¡Te has unido correctamente al predio!'
                    />
                </div>
                <Modal.Content>
                    <p 
                    className='f4 tc'>
                        Ahora solo inicia sesión en tu app con tu correo y tu contraseña 
                    </p>
                </Modal.Content>
            </Modal>
        {(typeof props.errors !== 'undefined') ? (
            <Modal
            basic
            size='small'>
                <div className='modal-header'>
                    <Header 
                    as='h1' 
                    color='red'
                    icon={<Icon
                            name='times'
                            className='tj'/>}
                    textAlign='right' 
                    content='¡Ha ocurrido un error!'
                    />
                </div>
                <Modal.Content>
                    <p 
                    className='f4 tc'>
                        Intentalo de nuevo más tarde
                    </p>
                </Modal.Content>
            </Modal>
        ) : null}
        </div>
    );
}

export default AcceptUserInvitationComponent;