import {useFetch} from 'use-http';
import SignupComponent from './SignupComponent';
import React, {useState, useRef,useEffect} from 'react';
import { ERROR_GLOBAL_UNDEFINED, ERROR_GLOBAL_PASSWORD_MATCH } from '../common/consts';

import { navigate } from '@reach/router';

const SignupContainer = props => {

    const [errors, setErrors] = useState();
    const [sended, setSended] = useState(false); 
    const [typeUsers, setTypeUsers] = useState([]);

    const [request] = useFetch({
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    });

    const getTypeUser = async () => {
        const dataTypeUsers = await request.get('/tipos_usuarios');
        let data = []
        if (dataTypeUsers === undefined) {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            let lng = dataTypeUsers.length
            for (let index = 0; index < lng; index++) {
                data.push(dataTypeUsers[index]);
            }
        } 
        setTypeUsers(data);
    }

    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) return
      mounted.current= true
      getTypeUser();
    })
    

    const onSubmit = formFields => {        
        if (formFields.password === formFields.passwordConfirm) {
            let data = new URLSearchParams();
            data.append('name',titleCase(formFields.name));
            data.append('email',formFields.email);
            data.append('password',formFields.password);
            data.append('tipos_usuarios_id',formFields.typeUsers);
            request.post('/user',data).then( res => {
                if (res  === undefined) {
                    setErrors(ERROR_GLOBAL_UNDEFINED);
                } else if (res.respuesta_id && res.respuesta_id !== '00') {
                    setErrors(res.mensaje);
                } else {
                    setSended(true);
                    setTimeout(() => navigate('/login') , 4500)
                }
            })
        } else {
            setErrors(ERROR_GLOBAL_PASSWORD_MATCH);
        }
    }

    const titleCase = str => {
        return str.toLowerCase().split(' ').map( word => {
          return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }

    return (
        <SignupComponent 
        sended={sended}
        errors={errors}
        onSubmit={onSubmit}
        typeUsers={typeUsers}
        load={request.loading}/>
    )
    
}

export default SignupContainer;