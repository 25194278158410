import ls from 'local-storage';
import useFetch from 'use-http';
import { useStateValue } from '../../../state';
import React, { useState, useEffect, useRef } from 'react';
import { ERROR_GLOBAL_UNDEFINED} from '../../common/consts';
import DispositivoDetailComponent from './DispositivoDetailComponent';

const DispositivoDetailContainer = props => {

    const [errors, setErrors] = useState();
    const [sensores, setSensores] = useState();
    const [{ dispositivoActual }, dispatch] = useStateValue();

    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});
    const getDispositivoDetail = async () =>{
        const dataDispositivos = await request.get('/biodispositivo_sensores/'+dispositivoActual.dispositivo_id);
        if (dataDispositivos === undefined || dataDispositivos.respuesta_id === '01' || dataDispositivos.respuesta_id === '04') {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            setSensores(dataDispositivos);
        }
    }

    const onClickHandler = sensor => {
        dispatch({
            type: 'setSensorActual',
            newSensorActual: sensor
        });
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'sensor'
        });
    }

    const onReturnHandler = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'newsfeed'
        });
        dispatch({
            type: 'setDispositivoActual',
            newDispositivoActual: null
        });
    }

    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) return
      mounted.current= true
      getDispositivoDetail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
        <DispositivoDetailComponent
        errors={errors}
        sensores={sensores}
        onClick={onClickHandler}
        onReturn={onReturnHandler}
        />
    );
}

export default DispositivoDetailContainer;