import React, { useEffect, useState } from 'react';
import { Card, Image, Transition } from 'semantic-ui-react';

const DetallePredioComponent = props => {

    const [predio, setPredio] = useState({
        activo: [],
        historial: []
    });

    let cultivosActivos = predio.activo.map((activo) =>
    <div
    key={activo.id}
    className='mb3 pointer'
    onClick={() => { 
        props.handleOnClick(activo) 
        }}>
        <Card
        className="center">
            <Card.Content>
                <Image
                size='mini'
                className='w-30'
                src='/img/cultivo-activo.svg'/>
                <p 
                className="v-mid ml3 dib truncate w-70 black">
                    {activo.nombre_cultivo}
                </p>
            </Card.Content>
        </Card>
    </div>
    );
    
    let historialCultivos = predio.historial.map((cultivo) =>
    <div
    key={cultivo.id}
    className='mb3 pointer'
    onClick={() => { props.handleOnClick(cultivo) }}>
        <Card
        className="center">
            <Card.Content>
                <Image
                size='mini'
                className='w-30'
                src='/img/cultivo-inactivo.svg'/>
                <p className="v-mid ml3 dib truncate w-70 gray">
                    {cultivo.nombre_cultivo}
                </p>
            </Card.Content>
        </Card>
    </div>
    );

    useEffect(() => {
        if (typeof props.cultivos.activo !== "undefined") {
            setPredio({
                activo: props.cultivos.activo,
                historial: predio.historial
            });
        }
        if (typeof props.cultivos.historial !== "undefined") {
            setPredio({
                activo: predio.activo,
                historial: props.cultivos.historial
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const isPredioNull = (predio) => {
        if (props.predio === null) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <div>
        {
            (isPredioNull(props.predio)) ? null : (
                <Transition.Group
                duration={1000}>
                    <Card 
                    className={"center " + (cultivosActivos && historialCultivos ? "mt3" : null)}>
                        <Card.Content>
                            <div>
                                <Image
                                    size='mini'
                                    src='/img/predio-negro.svg'
                                    className="w-30"
                                />
                                <p className="ml3 fw5 truncate w-70 dib v-mid">{props.predio.nombre}</p>
                            </div>
                            <div className="mt3">
                                <Image
                                    size='mini'
                                    src='/img/usuario.svg'
                                />
                                <span className="ml3">{props.predio.creador.name}</span>
                            </div>
                            <div className="mt3">
                                <Image
                                    size='mini'
                                    src='/img/candado.svg'
                                />
                                <span className="ml3">{props.predio.clave_predio}</span>
                            </div>
                        </Card.Content>
                    </Card>
                    {(predio.activo.length > 0) ? (
                        <div className="center">
                            <h2 className="white ml1-4">Cultivos activos</h2>
                            {cultivosActivos}
                        </div>
                    )
                        : null
                    }
                    {(predio.historial.length > 0) ? (
                        <div className="center">
                            <h2 className="white ml1-4 mt3" >Historial de cultivos</h2>
                            <div className="scroll-historial">
                                {historialCultivos}
                            </div>
                        </div>
                    )
                        : null
                    }
                    {
                        (predio.activo.length === 0 && predio.historial.length === 0) ? (
                            <Card
                            className='center pb3 pt3'>
                                <div
                                className='bitacoras-list tc'>
                                    <Image
                                    size='tiny'
                                    className='center mt3 mb3'
                                    src='/img/placeholder/cultivos.svg' />
                                    <span
                                    className='fw6 f4 dib'>
                                        No tienes cultivos
                                    </span>
                                    <span
                                    className='dib mb3 f5'>
                                        Crea uno desde nuestra app móvil
                                    </span>
                                </div>
                            </Card> 
                        ) : null
                    }
                </Transition.Group>
            )
        }
        </div>
    );
}

export default DetallePredioComponent;