import ls from 'local-storage';
import {useFetch} from 'use-http';
import { useStateValue } from '../../state';
import PrediosComponent from './PrediosComponent';
import React, {useState, useRef,useEffect} from 'react';
import { ERROR_GLOBAL_UNDEFINED} from '../common/consts';
import useMapPainter from '../common/hooks/useMapPainter';

const PrediosContainer = props => {

    const [paintMarker] = useMapPainter();
    const [query, setQuery] = useState("");
    const [errors, setErrors] = useState();
    const [pagina, setPagina] = useState(1);
    const [isDone, setIsDone] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [predios, setPredios] = useState({data: []});

    const [{ mapMarkers }, dispatch] = useStateValue();

    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});

    async function getPredios(pagina=1) {
        //Obtener los datos de los predios, de acuerdo a la página dada
        const dataPredios = await request.get('/mis_predios?pagina='+pagina+
            '&por_pagina=6'+
            '&q='+query);
        //Arrays vacios para los datos procesados
        let newData = [];
        let prediosMarkers = [];
        
        //Validación de las petición
        if (typeof dataPredios === "undefined" || typeof dataPredios.resultado === "undefined") { 
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            //Recorrer el resultado de la petición, llenar los arrays de predios y los marcadores del mapa
            let lng = dataPredios.resultado.length;
            for (let index = 0; index < lng; index++) {
                newData.push(dataPredios.resultado[index]);
                let polyline = JSON.parse(dataPredios.resultado[index].polyline)
                if (polyline !== null && typeof polyline !== 'undefined' && polyline !== []) {
                    if (polyline.length > 0){
                        prediosMarkers.push(
                            // Parámetros: img, lat, lng, unique, onClickFn 
                            paintMarker('/img/markers/predio-marker.svg', polyline[0][0], polyline[0][1], false, () => { handleOnClick(dataPredios.resultado[index])})
                        );
                    }
                }
            }
            //Agregar al estado los nuevos predios dados por la páginación sin perder los anteriores
            let oldState = predios.data;
            let newState = oldState.concat(newData);
            setPredios({
                data: newState
            });
            if (dataPredios.total_paginas === pagina) {
                setIsDone(true);
            }
            //Agregar al estado global los nuevos marcadores de predios dados por la páginación sin perder los anteriores
            let oldMarks = mapMarkers;
            let newMarks = oldMarks.concat(prediosMarkers);
            dispatch({
                type:'setMapMarkers',
                newMarkers: newMarks
            });
        } 
    }        

    const searchPredios = async () => {
        const dataPredios = await request.get('/mis_predios?q='+query);
        let newData = [];
        if(typeof dataPredios === 'undefined' || typeof dataPredios.resultado === 'undefined') {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            let lng = dataPredios.resultado.length;
            for (let index = 0; index < lng; index++) {
                newData.push(dataPredios.resultado[index]);
            }
            setPredios({
                data: newData
            });
            setIsDone(true);
        }
    }

    const onSearchPredio = (name) => {
        setQuery(name);
        searchPredios();
        return true;
    }

    const onShowMore = () => {
        let nuevaPagina = pagina + 1
        setPagina(nuevaPagina);
        getPredios(nuevaPagina);
    }

    const handleOnClick = predio => {
        dispatch({
            type: 'setPredioActual',
            newPredio: predio
        });
        document.getElementById('biobot-map').style.zIndex = 0;
        dispatch({
          type: 'setAppFocusRight',
          newAppFocusRight: 'detallePredio'
        });
    }

    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) return
        mounted.current= true
        getPredios();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onShareHandler = (predio) => {
        let shareStrg = "Nombre: "+predio.nombre+"\nClave: "+predio.clave_predio;
        navigator.clipboard.writeText(shareStrg);
    }

    return (
        <PrediosComponent 
        errors={errors} 
        isDone={isDone}
        isOpen={isOpen}
        predios={predios}
        setIsOpen={setIsOpen}
        onShowMore={onShowMore}
        loading={request.loading}
        handleOnClick={handleOnClick}
        onShareHandler={onShareHandler} 
        onSearchPredio={onSearchPredio} />   
    );
}

export default PrediosContainer;