import '../common/css/navbar.css';
import React, {useState} from 'react';
import { Menu, Image, Loader, Modal, Button } from 'semantic-ui-react';

const NavbarComponent = props => {

    const [state, setState] = useState({ activeItem: props.active });
    const { activeItem } = state

    const handleItemClick = (e, { name }) =>{
        
        setState({ activeItem: name });
        
        switch (name) {
            case 'predios':
                props.onClickPredios();
                break;
            case 'intelligence':
                props.onClickIntelligence();
                break;
            default:
                break;
        }
    } 

    return(
        <div>
            <Menu compact vertical borderless className='navbar'>
                <Menu.Item className='navbar-logo'>
                    <Image size='small' src='/img/logo.svg' />
                </Menu.Item>
                <Menu.Item name='predios' active={activeItem === 'predios'} onClick={handleItemClick}>
                    <Image className='navbar-icon' src={activeItem === 'predios' ? '/img/navbar/predios.svg' : '/img/navbar/predios-inactive.svg'}/>
                </Menu.Item> 
                <Menu.Item name='intelligence' onClick={handleItemClick} active={activeItem === 'intelligence'}>
                    <Image className='navbar-icon' src={activeItem === 'intelligence' ? '/img/navbar/inteligencia.svg' : '/img/navbar/inteligencia-inactive.svg'}/>
                </Menu.Item>
                <Menu.Item name='usuario' className='navbar-profile pointer' onClick={() => { props.onClickShowUser(true)}}>
                    <Image circular size='small' src={props.profilePicture} className='navbar-profile-picture'/>
                    {
                        (props.loading === true) ? (
                            <Loader active />
                        )
                        : null
                    }
                </Menu.Item>
            </Menu>
            { (typeof props.user !== 'undefined' && props.user !== null) ? (
                <Modal size="mini" className='br18px' open={props.showUser}>
                    <Modal.Content className='tc br18px'>
                        <Image src='/img/x.svg' className='pointer' onClick={() => {props.onClickShowUser(false)}}/>
                        <Image centered src={props.profilePicture} className='user-modal-image'/>
                        <div className='db mb4'>
                            <span className='fw5 f3 db'>{props.user.name}</span> 
                            <span className='f5 db'>{props.user.email}</span>
                        </div>
                        <Button fluid className='bsn mt3 br18px bg-lighter-gray' onClick={() => { props.onPolicy() }}>
                            <p className='pw5'>Políticas de privacidad</p>
                        </Button>
                        <Button fluid className='bsn mt3 br18px bg-lighter-gray' onClick={() => { props.onEmailContact() }}>
                            <p className='pw5'>Ayuda</p>
                        </Button>
                        <Button fluid className='bsn mt3 bg-red br18px' onClick={() => {props.onCloseSession()}}>
                            <p className='pw5 red'>Cerrar sesión</p>
                        </Button>
                    </Modal.Content>
                </Modal>
            ) : null}
        </div>
    )
}

export default NavbarComponent;