import React, { useEffect } from 'react';

import useForm from 'react-hook-form';
import { Grid, Message, Card, Input, Button, Form, Header, Loader, Modal, Icon } from 'semantic-ui-react';

import '../../common/css/floatForm.css';
import {MIN_FORMS_PASSWORD_LENGHT, ERROR_FORMS_EMTPY} from '../../common/consts.js';

const AcceptGuestInvitationComponent = props => {

    const {register, setValue, handleSubmit, errors} = useForm();
    
    const handleFormChange = type => event => {
        setValue(type, event.target.value);
    }

    useEffect(()=> {
        register({name: 'name'});
        register({name: 'phone'});
        register({name: 'password'}, {required: {value: true, message: ERROR_FORMS_EMTPY}, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}});
        register({name: 'passwordConfirm'}, {required: {value: true, message: ERROR_FORMS_EMTPY}, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}});
    },[register]);    

    return (
        <Grid
        centered
        columns={4}
        verticalAlign='middle'
        className='h-100'>
            <Grid.Row>
                <Grid.Column
                computer={5}
                mobile={14}
                tablet={8}
                textAlign='left'>
                    <Card
                    centered
                    fluid
                    raised={true} 
                    className='card-float-form'>
                        <Card.Content>
                            <Header
                            as='h2'
                            className='pt2'>
                                ¡Completa tus datos antes de unirte al predio!
                            </Header>
                            { (props.errors !== undefined) ? (
                                    <Message 
                                    negative>
                                        <Message.Header>{props.errors}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            { (typeof errors.password !== 'undefined' && typeof errors.passwordConfirm !== 'undefined') ? (
                                    <Message 
                                    negative>
                                        <Message.Header>Tienes que llenar todos los datos del formulario</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            {
                                (props.load === true) ? (
                                    <Loader 
                                    active />
                                )
                                : null
                            }
                            <Form
                            className='pt3'
                            onSubmit={handleSubmit(props.onSubmit)}>
                                <Form.Field>
                                    <Input 
                                    icon='user'
                                    type='text'
                                    name='name'
                                    size='big'
                                    className='float-form-input'
                                    placeholder='Nombre Completo'
                                    value={props.user.name}
                                    onChange={handleFormChange('name')}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input 
                                    icon='phone'
                                    type='number'
                                    name='phone'
                                    size='big'
                                    className='float-form-input'
                                    placeholder='Telefono'
                                    value={props.user.telefono}
                                    onChange={handleFormChange('phone')}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input 
                                    icon='unlock'
                                    type='password'
                                    name='password'
                                    size='big'
                                    className='float-form-input'
                                    placeholder='Contraseña'
                                    onChange={handleFormChange('password')}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input 
                                    icon='lock'
                                    type='password'
                                    name='passwordConfirm'
                                    size='big'
                                    className='float-form-input'
                                    placeholder='Confirmar contraseña'
                                    onChange={handleFormChange('passwordConfirm')}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button 
                                    fluid 
                                    size='big' 
                                    type='submit' 
                                    content='Aceptar invitación' 
                                    className='float-form-button'/>
                                </Form.Field>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
            <Modal
            open={props.sended}
            basic
            size='small'>
                <div className='modal-header'>
                    <Header 
                    as='h1' 
                    color='green'
                    icon={<Icon
                            name='handshake'
                            className='tj'/>}
                    textAlign='right' 
                    content='¡Te has unido correctamente al predio!'
                    />
                </div>
                <Modal.Content>
                    <p 
                    className='f4 tc'>
                        Ahora solo inicia sesión en tu app con tu correo y tu contraseña 
                    </p>
                </Modal.Content>
            </Modal>
        </Grid>
    );

}

export default AcceptGuestInvitationComponent;