import React from 'react';
import { Image } from 'semantic-ui-react';

const ErrorComponent = props => {

    return(
        <div className="error-container">
            <div>
                <Image src='/img/inteligencia/arrow-left.svg' className='dib' as='button' onClick={props.selectReturn()}></Image>
                <p className='dib charcoal-grey'>Regresar</p> 
            </div>
            <h2>Lo sentimos, ha ocurrido un error :(</h2>
            <p>{props.error}</p>
        </div>
    );
}

export default ErrorComponent;