import React from 'react';
import ErrorComponent from './ErrorComponent';

import { useStateValue } from '../../state';
import '../common/css/error.css';

const ErrorContainer = props => {
    
    // eslint-disable-next-line no-unused-vars
    const [{ inteligenciaFocus }, dispatch] = useStateValue();
    const error = props.error;

    const returnHandler = () => {
        dispatch({
            type: 'setInteligenciaFocus',
            newInteligenciaFocus: null
        });
    };

    return(
        <ErrorComponent 
            error={error}
            selectReturn = {() => returnHandler}
        />
    );
}

export default ErrorContainer;
