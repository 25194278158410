import React from 'react';
import ellipsis from 'text-ellipsis';
import { Card, Image, Message, Icon, Grid, Modal, Button } from 'semantic-ui-react';

const BitacoraDetailComponent = props => {

    let archivos = [];    

    if (typeof props.bitacora !== 'undefined') {
        props.bitacora.archivos.forEach(archivo => {
            archivos.push(
                <Grid.Column
                key={Math.random()}>
                    <Image
                    src={archivo.curl}
                    className='w-100 h-120px br17px pointer'
                    onClick={() => { window.open(archivo.curl) }} 
                    onError={ ( e ) => { 
                        e.target.onerror = null; 
                        e.target.src= '/img/placeholder-archivo.svg';
                    }}/>
                </Grid.Column>
            );
        });
    }

    return (
        <div>
            {(typeof props.bitacora !== 'undefined') ? (
                <Card
                fluid
                className='center pa1 pt2 pb1'>
                    <Card.Content>
                        <div>
                            <Image
                            inline
                            className='pointer'
                            src='/img/arrow-left.svg'
                            onClick={() => { props.onReturn()} }/>
                        </div>
                        <div
                        className='dib mt3 mb2'>
                            <Image
                            inline
                            className='w2'
                            src={'/img/bitacoras/' + props.header.img}/>
                            <span
                            className={'dib ml2 fw5 ' + props.header.color}>
                                {props.header.header}
                            </span>
                        </div>
                        <div>
                            <span
                            className='b'>
                                {props.bitacoraActual.name.toUpperCase()}
                            </span>
                        </div>
                        <div>
                            <span>
                                {props.bitacora.notas}
                            </span>
                        </div>
                        <div
                        className='flex mt2'>
                            <Image
                            inline
                            className='w2'
                            src='/img/predio-negro.svg' />
                            <div
                            className='flex flex-column ml3'>
                                <span
                                className='light-gray f6'>
                                    Predio
                                </span>
                                <span>
                                    {props.predio.nombre}
                                </span>
                            </div>
                        </div>
                        <div
                        className='flex mt2'>
                            <Image
                            inline
                            className='w2'
                            src='/img/calendario.svg' />
                            <div
                            className='flex flex-column ml3'>
                                <span
                                className='light-gray f6'>
                                    Fecha de inicio
                                </span>
                                <span>
                                    {props.bitacoraActual.initDate}
                                </span>
                            </div>
                        </div>
                        {props.bitacoraActual.endDate ? (
                        <div
                        className='flex mt2'>
                            <Image
                            inline
                            className='w2'
                            src='/img/calendario.svg' />
                            <div
                            className='flex flex-column ml3'>
                                <span
                                className='light-gray f6'>
                                    Fecha final
                                </span>
                                <span>
                                    {props.bitacoraActual.endDate}
                                </span>
                            </div>
                        </div>
                        ) : null}
                        <div
                        className='flex mt2'>
                            <Image
                            inline
                            className='w2'
                            src='/img/cultivo-negro.svg' />
                            <div
                            className='flex flex-column ml3'>
                                <span
                                className='light-gray f6'>
                                    Tipo de cultivo
                                </span>
                                <span>
                                    {props.cultivo.tipo_cultivo}
                                </span>
                            </div>
                        </div>
                        <div
                        className='mt3'>
                            <span
                            className='fw5'>
                                Usuario
                            </span>
                            <div
                            className='mt2'
                            onClick={ () => {props.onShowUser(true)} }>
                                <Card
                                className='bg-lighter-gray bsn pointer'>
                                    <Card.Content>
                                        <Image
                                        inline
                                        className='bitacora-user-img'
                                        src={ props.bitacoraActual.user.image ? props.bitacoraActual.user.image : '/img/usuario.svg'}/>
                                        <span
                                        className='fw5 ml3 black'>
                                            {ellipsis(props.bitacoraActual.user.name, 20)}
                                        </span>
                                        <Icon
                                        name='chevron right'
                                        className='fr mt0-75 lh-solid black' />
                                    </Card.Content>
                                </Card>
                            </div>
                        </div>
                        <div
                        className='mt3'>
                            <p
                            className='fw5'>
                                Documentos ({props.bitacora.archivos.length})
                            </p>
                            <Grid>
                                <Grid.Row
                                columns={2}>
                                    {archivos}
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Card.Content>
                </Card>
            ) : props.errors ? (
                <Message
                icon
                negative>
                    <Icon
                    name='arrow left'
                    className='pointer'
                    onClick={props.onReturn}/>
                    <Message.Content>
                        {props.errors}
                    </Message.Content>
                </Message>
            ) : null}
            { ( typeof props.bitacoraActual !== 'undefined' ) ? (
                <Modal
                size='tiny'
                className='br18px dib'
                open={props.showUser}>
                    <Modal.Content
                    className='tc br18px'>
                        <Image
                        src='/img/x.svg'
                        className='pointer'
                        onClick={() => {props.onShowUser(false)}}/>
                        <Image 
                        centered 
                        className='user-modal-image'
                        src={ props.bitacoraActual.user.image ? props.bitacoraActual.user.image : '/img/usuario.svg' }/>
                        <span
                        className='fw5 f3'>
                            {props.bitacoraActual.user.name}
                        </span>
                        { props.bitacoraActual.user.phone !== null ? (
                            <Button
                            fluid
                            className='bsn mt3 br18px pb0 pt0'
                            onClick={() => {props.onPhoneContact(props.bitacoraActual.user.phone)}}>
                                <div
                                className='ma3 pr4-5'>
                                    <Image
                                    floated='left'
                                    className='ma0'
                                    src='/img/phone.svg'/>
                                    <span
                                    className='pw5 v-mid'>
                                        {props.bitacoraActual.user.phone}
                                    </span>
                                </div>
                            </Button>
                        ) : null }
                        <Button
                        fluid
                        className='bsn mt3 br18px pb0 pt0'
                        onClick={() => {props.onEmailContact(props.bitacoraActual.user.email)}}>
                        <div
                        className='ma3 pr4-5'>
                            <Image
                            floated='left'
                            className='ma0'
                            src='/img/mail.svg'/>
                            <span
                            className='pw5 v-mid'>
                                {props.bitacoraActual.user.email}
                            </span>
                        </div>
                        </Button>
                    </Modal.Content>
                </Modal>
            ) : null}
        </div>
    );

}

export default BitacoraDetailComponent; 