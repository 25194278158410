import React from 'react';
import Index from './Index';
import ls from 'local-storage';
import { navigate } from '@reach/router';

const IndexContainer = props => {

    if (ls.get('auth')) {
        navigate('/home');
    }

    return(
        <Index/>
    )
}

export default IndexContainer
