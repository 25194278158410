import React from 'react';

const LoadingComponent = props => {

    return(
        <div className="loading-container">
            <div class="ui active transition visible dimmer">
                <div class="content">
                    <div class="ui loader"></div>
                </div>
            </div>
        </div>
    );
}

export default LoadingComponent;