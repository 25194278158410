import React, {useEffect} from 'react'
import useForm from 'react-hook-form'
import { Grid, Message, Card, Input, Button, Form, Header, Modal, Icon, Loader } from 'semantic-ui-react'
import { REGEX_EMAIL_VALIDATION } from '../common/consts';
import '../common/css/floatForm.css'

const ForgotComponent = props => {

    const { register, handleSubmit, setValue } = useForm();

    const handleFormChange = type => event => {
        setValue(type, event.target.value);
    }

    useEffect(()=> {
        register({name: 'email'}, {required: true, pattern: REGEX_EMAIL_VALIDATION})
    }, [register]);

    return(
        <Grid centered columns={4} verticalAlign='middle' className="h-100">
            <Grid.Row>
                <Grid.Column computer={5} mobile={14} tablet={8} textAlign="left" >
                    <Card centered fluid raised={true} className='card-float-form'>
                        <Card.Content>
                            <Header as='h1' className='pt2'>¿Olvidaste tu contraseña?</Header>
                            { (props.errors !== undefined) ? (
                                    <Message negative>
                                        <Message.Header>{props.errors}</Message.Header>
                                    </Message>
                                    )
                                    : null
                            }
                            {
                                (props.load === true) ? (
                                    <Loader active />
                                )
                                : null
                            }
                            <Form onSubmit={handleSubmit(props.onSubmit)} className='pt3'>
                                <Form.Field>
                                    <Input icon='mail' type="email" name="email" placeholder="Correo Electronico" 
                                        className='float-form-input' size="big" onChange={handleFormChange('email')} />
                                </Form.Field>
                                <Form.Field className='pt3'>
                                    <Button fluid type='submit' content='Recuperar contraseña' size="big" className='float-form-button'/>
                                </Form.Field>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
                <Modal
                open={props.sended}
                basic size='small'>
                <Header as='h1' icon='paper plane' content='¡Revisa tu bandeja de entrada!' />
                <Modal.Content>
                <p className='f4'>
                    Hemos enviado un correo electronico a la dirección que nos proporcionaste. <br/> <br/>
                    Ahí podrás encontrar un enlace que te llevará a donde podrás recuperar tu cuenta.
                </p>
                </Modal.Content>
                <Modal.Actions>
                <Button color='green' onClick={()=> {props.setSended(false)}} inverted>
                    <Icon name='checkmark'/> Enterado
                </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    )

}

export default ForgotComponent;