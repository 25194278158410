import React from 'react';
import ellipsis from 'text-ellipsis';
import { Popup } from 'semantic-ui-react';

const TableCardRow = (props) => {

    return (
        <tr className='data-row'>
            <td className='left-border'>{props.date}</td>
            <td>{props.duration}</td>
            <td>
                <Popup content={props.user} trigger={
                    <span className='pre'>{ellipsis(props.user, 25)}</span>
                }/>
            </td>
            <td>{props.mail}</td>
            <td>{props.registry}</td>
            <td>
                <Popup content={props.notes} trigger={
                    <span className='pre'>{ellipsis(props.notes, 25)}</span>
                }/>
            </td>
            <td>{props.documents}</td>
            <td>{props.crop}</td>
            <td>
                {
                    typeof props.state === 'undefined'
                    ?   (<span className='pre'>N/A</span>) 
                    :   (<Popup content={props.state} trigger={
                            <span className='pre'>{ellipsis(props.state, 12)}</span>
                            }/>
                        )
                }
            </td>
            <td className='right-border'>
                {
                    typeof props.city === 'undefined'
                    ?   (<span className='pre'>N/A</span>)
                    :   (<Popup content={props.city} trigger={
                            <span className='pre'>{ellipsis(props.city, 12)}</span>
                            }/>
                        )
                }
            </td>
        </tr>        
    );
}

export default TableCardRow;