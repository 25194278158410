import 'moment/locale/es';
import React from 'react';
import { useStateValue } from '../../state';

import MenuComponent from './MenuComponent';
import ReportContainer from './IntelligenceContainer';
import AnalysisContainer from './options/AnalysisContainer';

const MenuContainer = props => {

    const [{ inteligenciaFocus, loggedUser }, dispatch] = useStateValue();

    const reportHandler = () => {
        dispatch({
            type: 'setInteligenciaFocus',
            newInteligenciaFocus: 'reporte'
        });
    }

    const analysisHandler = (e) => {
        dispatch({
            type: 'setInteligenciaFocus',
            newInteligenciaFocus: 'analisis'
        });
    }

    // const planHandler = (e) => {
    //     dispatch({
    //         type: 'setInteligenciaFocus',
    //         newInteligenciaFocus: 'plan'
    //     });
    // }

    switch(inteligenciaFocus) {
        case null: 
            return (<MenuComponent
                user = {loggedUser}
                selectReport = {() => reportHandler}   
                selectAnalysis = {() => analysisHandler}
            />)
        case 'reporte':
            return (<ReportContainer/>);
        case 'analisis':
            return (<AnalysisContainer/>);
        // case 'plan':
        //     // return ();
        default:
            break;
    }
}

export default MenuContainer;