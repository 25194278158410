import React from 'react';
import MapComponent from './MapComponent';
import { useStateValue } from '../../state';

const MapContainer = props => {
    
    const [{
        mapZoom, 
        mapCenter,
        mapMarkers,
        mapPolygons,
        }] = useStateValue();
    
    return (
        <MapComponent 
        zoom={mapZoom} 
        center={mapCenter} 
        markers={mapMarkers} 
        polygons={mapPolygons}/>
    );
}

export default MapContainer;
