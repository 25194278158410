import React, {useEffect} from 'react';
import useForm from 'react-hook-form';
import { Grid, Message, Card, Input, Button, Form, Header, Loader, Modal, Dropdown } from 'semantic-ui-react';
import {MIN_FORMS_PASSWORD_LENGHT, REGEX_EMAIL_VALIDATION} from '../common/consts'
import '../common/css/floatForm.css';

import { Link } from '@reach/router'

const SignupComponent = props => {

    const { register, handleSubmit, setValue, errors } = useForm();

    const handleFormChange = type => event => {
        setValue(type, event.target.value);
    }

    const handleDropdownChange = (e, { name, value }) => setValue(name, value);
    
    let dropdownData = []
    
    for (let index = 0; index < props.typeUsers.length; index++) {
        dropdownData.push({
            key: props.typeUsers[index].id,
            text: props.typeUsers[index].tipo,
            value: props.typeUsers[index].id,
        })
    }

    useEffect(()=> {
        register({name: 'name'}, {required: true});
        register({name: 'email'}, {required: true, pattern: REGEX_EMAIL_VALIDATION});
        register({name: 'password'}, {required: true, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}});
        register({name: 'passwordConfirm'}, {required: true, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}});
        register({name: 'typeUser'}, {required: true})
    }, [register]);
    
    return(
        <Grid centered columns={4} verticalAlign='middle' className="h-100">
            <Grid.Row>
                <Grid.Column computer={5} mobile={14} tablet={8} textAlign="center" >
                    <Card centered fluid raised={true} className='card-float-form'>
                        <Card.Content>
                            <Header as='h1' className='pt2'>¡Únete a Biobot hoy!</Header>
                            { (props.errors !== undefined) ? (
                                    <Message negative>
                                        <Message.Header>{props.errors}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            {
                                (props.load === true) ? (
                                    <Loader active />
                                )
                                : null
                            }
                            { (errors.password) ? (
                                    <Message negative>
                                        <Message.Header>{errors.password && errors.password.message}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            { (errors.passwordConfirm) ? (
                                    <Message negative>
                                        <Message.Header>{errors.passwordConfirm && errors.passwordConfirm.message}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            {
                                (props.load === true) ? (
                                    <Loader active />
                                )
                                : null
                            }
                            <Form onSubmit={handleSubmit(props.onSubmit)} className='pt3'>
                                <Form.Field>
                                    <Input icon="user" type="text" name="name" placeholder="Nombre completo"
                                    className="float-form-input" size="big" onChange={handleFormChange('name')}/>
                                </Form.Field>
                                <Form.Field>
                                    <Input icon='envelope' type="email" name="email" placeholder="Correo electrónico" 
                                        className='float-form-input' size="big" onChange={handleFormChange('email')} />
                                </Form.Field>
                                <Form.Field>
                                    <Input icon='unlock' type="password" name="password" placeholder="Nueva contraseña"
                                        className='float-form-input'size="big" onChange={handleFormChange('password')} />
                                </Form.Field>
                                <Form.Field>
                                    <Input icon='lock' type="password" name="passwordConfirm" placeholder="Confirmar contraseña"
                                        className='float-form-input'size="big" onChange={handleFormChange('passwordConfirm')} />
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown fluid selection placeholder='Tipo de usuario' options={dropdownData} name="typeUser"
                                    className='float-form-dropdown' size='big' icon='dropdown' onChange={handleDropdownChange}/>
                                </Form.Field>
                                <Form.Field className='pt3'>
                                    <Button fluid type='submit' content='Registrarse' size="big" className='float-form-button'/>
                                </Form.Field>
                                <Form.Field className='pt0'>
                                    <Link className='float-form-link' to='/login'>¿Ya tienes cuenta? Inicia sesión aquí</Link>
                                </Form.Field>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
            <Modal
                open={props.sended}
                basic size='small'>
                <Header as='h1' icon='paper plane' content='¡Registro exitoso!' />
                <Modal.Content>
                <p className='f4'>
                    Revisa tu correo electonico para confirmar tu cuenta.
                </p>
                </Modal.Content>
                <Modal.Actions>

                </Modal.Actions>
            </Modal>
        </Grid>
    )

}

export default SignupComponent;