import 'moment/locale/es';
import moment from 'moment';
import ls from 'local-storage';
import useFetch from 'use-http';
import { useStateValue } from '../../../state';
import categorizeBitacoras from '../categorizeBitacoras';
import React, { useState, useEffect, useRef } from 'react';
import BitacoraListComponent from './BitacoraListComponent';
import { ERROR_GLOBAL_UNDEFINED} from '../../common/consts';

moment.locale('es');

const BitacoraListContainer = props => {
    
    const [errors, setErrors] = useState();
    const [{ cultivoActual }] = useStateValue();
    const [today, setToday] = useState( new Date() );
    const [aWeekFromNow, setAWeekFromNow] = useState( new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000) );
    const [bitacora, setBitacora] = useState({
        sintoma: [],
        cosecha: [],
        producto: [],
        enfermedad: [],
        actividades: [],
    });
 
    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});
    async function getBitacoraList() {

        let initDate = escape(moment(today).format('YYYY-MM-DD H:M:S'));
        let endDate = escape(moment(aWeekFromNow).format('YYYY-MM-DD H:M:S'));
        
        const dataBitacora = await request.get('/cultivo/'
        +cultivoActual.id+'/bitacora/general?fecha_inicio='
        +initDate+'&fecha_final='
        +endDate+'&por_pagina=70&pagina=1');
        if (dataBitacora === undefined) {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            let bitacorasList = categorizeBitacoras(dataBitacora.resultado);
            setBitacora(bitacorasList);
        } 
    }


    const onChangeToday = ( date ) => {
        setToday(date);
    }

    const onChangeAWeekFromNow = ( date ) => {
        setAWeekFromNow(date);
    }

    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) return
        mounted.current= true
        getBitacoraList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        getBitacoraList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [today, aWeekFromNow]);

    return(
        <BitacoraListComponent
        today={today}
        errors={errors}
        sintoma={bitacora.sintoma}
        cosecha={bitacora.cosecha}
        aWeekFromNow={aWeekFromNow} 
        producto={bitacora.producto}
        onChangeToday={onChangeToday}
        enfermedad={bitacora.enfermedad}
        actividades={bitacora.actividades}
        onChangeAWeekFromNow={onChangeAWeekFromNow}
        />
    );

}
export default BitacoraListContainer;