import React from 'react';
import { Card, Image, Grid } from 'semantic-ui-react';

const ForecastComponent = props => {

    let cartasClima = [];

    let vic = props.daily.slice(0,4);

    vic.forEach((element)=>{
        cartasClima.push(
            <Card className="bg-lighter-gray ml1-4 center bsn" key={element.date}>
                <div className='tc ma2'>
                    <Image
                        inline
                        src={'/img/forecast/'+element.icon+'.svg'}
                        className="w-70 h-25px"
                        />
                </div>
                <div>
                    <p className='gray f7 mb0'>
                        {element.date}
                    </p>
                    <span className="f7">
                        {element.min+'/'+element.max}
                    </span>
                    <div className="mb2">
                        <Image
                            src='/img/forecast/umbrella.svg'
                            className='w-30'
                            />
                        <span className="f7">
                            {element.prob}
                        </span>
                    </div>
                </div>
            </Card>
        );
    });

    return(
        <Card className='center pa1 ' fluid>
            <Card.Content>
                <div>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3} className="pa0" textAlign="center">
                                <Image
                                    src={'/img/forecast/'+props.today.icon+'.svg'}
                                    size='mini'
                                    className='w-70'
                                    />
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <p className="fw5 mb0">Clima actual</p>
                                <span className='gray f7'>{props.today.min+'C min - '+props.today.max+'C max'}</span>
                                <Image
                                    inline
                                    src='/img/forecast/umbrella-gray.svg'
                                    className='w-10 ml1 mr1 mb1'
                                    />
                                <span className='gray f7'>{props.today.prob}</span>
                            </Grid.Column>
                            <Grid.Column width={3} className="pa0" textAlign="center" verticalAlign="middle">
                                <div className="mr4">
                                    <span className='f3'>{props.today.temp}</span>
                                    <span className="absolute top--5px">°C</span>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <div>
                    <Grid columns='equal' padded>
                        <Grid.Row className="pb0">
                            {cartasClima !== [] ? (
                                cartasClima.map((carta)=>{
                                    return(
                                    <Grid.Column className='pa1' textAlign="center" key={Math.random()}>
                                        {carta}
                                    </Grid.Column>
                                    )
                                })
                                ) : null}
                        </Grid.Row>
                    </Grid>
                </div>
            </Card.Content>
        </Card>
    );

}

export default ForecastComponent;