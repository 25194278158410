/**
 *  Hook con funcionalidades para el manejo de los poligonos que regresa la API en forma de 'polylines'.
 */
const usePolygonManager = () => {

    /**
     * Dado las coordenadas de un polígono, devuelve las coordenadas de su centro.
     * @param {Number[]} polygon - Arreglo de coordenadas que conforman el polígono.
     * @returns {Number[]} - Un arreglo con coordenadas en X y en Y.
     */
    const getPolygonCenter = ( polygon ) => {
        var x = polygon.map (x => x[0]);
        var y = polygon.map (x => x[1]);

        var cx = (Math.min (...x) + Math.max (...x)) / 2;
        var cy = (Math.min (...y) + Math.max (...y)) / 2;

        return [
            cx,
            cy
        ];
    }

    /**
     * Dada una string con la polyline, te devolverá un arrgelo con JSONs con las coordenadas.
     * @param {String} polyline - Un string de un arreglo con las coordinadas del polígono. 
     * @returns {JSON[]} - Un arreglo con las coordinadas (E.j.: {lat:x, lng:y}).
     */
    const polylineToCoords = ( polyline ) => {
            let polygon = JSON.parse(polyline);
            let coords = [];

            polygon.forEach( element => {
                coords.push({
                    lat: element[0],
                    lng: element[1]
                });
            });

            return coords;
    }

    /**
     * Dado un objecto, con una propiedad de polyline regresa un arreglo con todos los polígonos de ese objecto.
     * @param {Object} object - Un objeto con una propiedad de polyline (E.g.: cultivo.polyline). 
     * @returns {Number[]} - Un arreglo con las coordenadas de uno o más polígonos.
     */
    const objectToArray = ( object ) => {
        let array = [];

        object.forEach( element => {
            let polyline = JSON.parse(element.polyline);
            array.push(polyline);
        });

        return array;
    }

    return [
        objectToArray,
        getPolygonCenter,
        polylineToCoords,
    ];

}

export default usePolygonManager;
