import './App.css';
import React from 'react';

//Librerias
import ls from 'local-storage';
import { Provider } from 'use-http';
import { Router } from '@reach/router';

//Estado global
import { StateProvider } from './state';

//Componentes
import HomeContainer from './app/home/HomeContainer';
import ResetContainer from './app/reset/ResetContainer';
import LoginContainer from './app/login/LoginContainer';
import IndexContainer from './app/index/IndexContainer';
import ForgotContainer from './app/forgot/ForgotContainer';
import SignupContainer from './app/signup/SignupContainer';
import AcceptUserInvitationContainer from './app/acceptInvitation/user/AcceptUserInvitationContainer';
import AcceptGuestInvitationContainer from './app/acceptInvitation/guest/AcceptGuestInvitationContainer';


const options = {
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': '*/*',
        'Authorization': ls.get('auth') || null 
        //TODO: No se mete el token en la primer recarga, se resolvió metiendo el auth en cada petición de predios. Hay que arreglar eso.
    }
}

function App() {

    const initialState = {
        predioActual: null,
        cultivoActual: null,
        bitacoraActual: null,
        dispositivoActual: null,
        sensorActual: null,
        appFocus: 'predios',
        appFocusRight: null,
        appFocusLeft: 'predios',
        loggedUser: null,
        mapMarkers: [],
        mapPolygons: [],
        mapZoom: 5,
        mapCenter: { lat: 20.5167, lng: -99.8667 },
        inteligenciaFocus: null
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'setPredioActual':
                return {
                    ...state,
                    predioActual: action.newPredio
                };
            case 'setCultivoActual':
                return {
                    ...state,
                    cultivoActual: action.newCultivo
                };
            case 'setBitacoraActual':
                return {
                    ...state,
                    bitacoraActual: action.newBitacoraActual
                };
            case 'setDispositivoActual':
                return {
                    ...state,
                    dispositivoActual: action.newDispositivoActual
                };
            case 'setSensorActual':
                return {
                    ...state,
                    sensorActual: action.newSensorActual
                };
            case 'setAppFocus':
                return {
                    ...state,
                    appFocus: action.newAppFocus
                };
            case 'setAppFocusRight':
                return {
                    ...state,
                    appFocusRight: action.newAppFocusRight
                };
            case 'setAppFocusLeft':
                return {
                    ...state,
                    appFocusLeft: action.newAppFocusLeft
                };
            case 'setLoggedUser':
                return {
                    ...state,
                    loggedUser: action.newLoggedUser
                };
            case 'setMapMarkers':
                return {
                    ...state,
                    mapMarkers: action.newMarkers
                };
            case 'setMapPolygons':
                return {
                    ...state,
                    mapPolygons: action.newPolygons
                };
            case 'setMapZoom':
                return {
                    ...state,
                    mapZoom: action.newZoom
                }
            case 'setMapCenter':
                return {
                    ...state,
                    mapCenter: action.newCenter
                }
            case 'setInteligenciaFocus':
                return {
                    ...state,
                    inteligenciaFocus: action.newInteligenciaFocus
                }
            case 'setSelectedPredioAnalisis':
                return {
                    ...state,
                    selectedPredioAnalisis: action.newSelectedPredioAnalisis
                }
            default:
                return state;
        }
    };

    return (
        <div id="App">
            <StateProvider initialState={initialState} reducer={reducer}>
                <Provider url={process.env.REACT_APP_API} options={options}>
                        <Router>
                            <IndexContainer path="/" />
                            <HomeContainer  path='/home' />
                            <LoginContainer path='/login' />
                            <ForgotContainer path="/forgot" />
                            <SignupContainer path='/signup' />
                            <ResetContainer path='/reset/:token' />
                            <AcceptGuestInvitationContainer path='/acceptPredio/guest/:token' />
                            <AcceptUserInvitationContainer path='/acceptPredio/user/:token' />
                        </Router>
                </Provider>
            </StateProvider>
        </div>
    );
}

export default App;
