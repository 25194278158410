import React, {useEffect} from 'react';
import useForm from 'react-hook-form';
import { Grid, Message, Card, Input, Button, Form, Header, Loader, Modal } from 'semantic-ui-react';
import {MIN_FORMS_PASSWORD_LENGHT} from '../common/consts'
import '../common/css/floatForm.css';

const ResetComponent = props => {
    
    const { register, handleSubmit, setValue, errors } = useForm();

    const handleFormChange = type => event => {
        setValue(type, event.target.value);
    }

    useEffect(()=> {
        register({name: 'password'}, {required: true, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}});
        register({name: 'passwordConfirm'}, {required: true, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}});
    }, [register]);

    return(
        <Grid centered columns={4} verticalAlign='middle' className="h-100">
            <Grid.Row>
                <Grid.Column computer={5} mobile={14} tablet={8} textAlign="left" >
                    <Card centered fluid raised={true} className='card-float-form'>
                        <Card.Content>
                            <Header as='h1' className='pt2'>Ingresa tu nueva contraseña</Header>
                            { (props.errors !== undefined) ? (
                                    <Message negative>
                                        <Message.Header>{props.errors}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            {
                                (props.load === true) ? (
                                    <Loader active />
                                )
                                : null
                            }
                            { (errors.password) ? (
                                    <Message negative>
                                        <Message.Header>{errors.password && errors.password.message}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            { (errors.passwordConfirm) ? (
                                    <Message negative>
                                        <Message.Header>{errors.passwordConfirm && errors.passwordConfirm.message}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            {
                                (props.load === true) ? (
                                    <Loader active />
                                )
                                : null
                            }
                            <Form onSubmit={handleSubmit(props.onSubmit)} className='pt3'>
                                <Form.Field>
                                    <Input icon='unlock' type="password" name="password" placeholder="Nueva contraseña"
                                        className='float-form-input'size="big" onChange={handleFormChange('password')} />
                                </Form.Field>
                                <Form.Field>
                                    <Input icon='lock' type="password" name="passwordConfirm" placeholder="Confirmar"
                                        className='float-form-input'size="big" onChange={handleFormChange('passwordConfirm')} />
                                </Form.Field>
                                <Form.Field className='pt3'>
                                    <Button fluid type='submit' content='Restablece tu acceso' size="big" className='float-form-button'/>
                                </Form.Field>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
            <Modal
                open={props.sended}
                basic size='small'>
                <Header as='h1' icon='check' content='¡Nueva contraseña registrada!' />
                <Modal.Content>
                <p className='f4'>
                    La próxima que vez que inicies sesión usa tu nueva contraseña.
                </p>
                </Modal.Content>
                <Modal.Actions>

                </Modal.Actions>
            </Modal>
        </Grid>
    )

}

export default ResetComponent;