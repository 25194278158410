import ls from 'local-storage';
import { useFetch } from 'use-http';
import { useStateValue } from '../../../state';
import React, {useState, useRef, useEffect} from 'react';
import { ERROR_GLOBAL_UNDEFINED} from '../../common/consts';
import useMapPainter from '../../common/hooks/useMapPainter';
import DetallePredioComponent from './DetallePredioComponent';
import usePolygonManager from '../../common/hooks/usePolygonManager';

const DetallePredioContainer = props => {
    
    const [errors, setErrors] = useState();
    const [paintMarker, paintPolygon] = useMapPainter();
    const [{ predioActual }, dispatch] = useStateValue();
    const [
        objectToArray,
        getPolygonCenter, 
        polylineToCoords,    
    ] = usePolygonManager();
    
    const [cultivos, setCultivos] = useState({
        activo: [],
        historial: [],
    });
    // eslint-disable-next-line
    const [paginas, setPaginas] = useState({
        paginas: {
            pagina: 1,
            por_pagina: 5
        }
    })

    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});

    const listCultivos = async (type) => {
        /**
         * NOTA: El tipo (type) de esta función recibe es true/false,
         * este hace referencia al parametro activo de la ruta, por lo que type
         * tiene una equivalencia de true = activo, false = historial.
         */

        const dataCultivos = await request.get('/cultivos_predio/'+predioActual.id
            +'?activos='+type
            +'&pagina='+paginas.paginas.pagina
            +'&por_pagina='+paginas.paginas.por_pagina);

        let data = [];

        if (typeof dataCultivos === "undefined" || typeof dataCultivos.resultado === "undefined") { 
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {

            let lng = dataCultivos.resultado.length;
            for (let index = 0; index < lng; index++) {
                data.push(dataCultivos.resultado[index]);
            }

            if (type === 'true') { 
                setPolygons(data, {stroke: '#f4f3f3', fill:'#f4f3f3'});
                setCultivos({
                    activo: data,
                });
            } else {
                setCultivos({
                    historial: data
                });
            }

        }

    }

    const setPolygons= (cultivos, {stroke, fill}) => {
        if (cultivos.length > 0) {
            let polygons = []
            let polygonData = objectToArray(cultivos);

            cultivos.forEach(element => {
                let coords = polylineToCoords(element.polyline);
                polygons.push(paintPolygon(stroke, fill, coords, false));
            });
            
            setCultivoMarkers(polygonData, cultivos);
            dispatch({
                type:'setMapPolygons',
                newPolygons: polygons
            });
        }
    }

    const setCultivoMarkers = (polygons, cultivos) => {
        
        let markers = []

        let firstCenter = {
            lat: null,
            lng: null
        }
        //Se recorren todos los cultivos del predio actual
        for (let index = 0; index < polygons.length; index++) {
            
            const element = polygons[index];
            let center = getPolygonCenter(element);

            if (index === 0) {
                firstCenter = {
                    lat: center[0],
                    lng: center[1]
                }
            }

            let newMarker = paintMarker('/img/markers/cultivo-marker.svg', center[0], center[1], false, () => { handleOnClick(cultivos[index]) } );
            markers.push(newMarker);
        }

        dispatch({
            type:'setMapMarkers',
            newMarkers: markers
        });
        //Se hace zoom y se centra en el predio, en el primer cultivo
        //TODO: Pedir a Chato ruta del zoom del zoom del predio
        dispatch({
            type:'setMapCenter',
            newCenter: {
                lat: firstCenter.lat,
                lng: firstCenter.lng
            }
        });
        dispatch({
            type:'setMapZoom',
            newZoom: 18
        });
    }

    const getCultivos = () => {
        if (predioActual !== null) {
            listCultivos('true');
            listCultivos('false');
        }
    }

    const handleOnClick = cultivo => {
        document.getElementById('biobot-map').style.zIndex = 0;
        setPolygons([cultivo], {stroke: '#009244', fill: '#00ce53'});
        dispatch({
            type: 'setCultivoActual',
            newCultivo: cultivo
        });
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'newsfeed'
        });
        dispatch({
            type: 'setAppFocusLeft',
            newAppFocusLeft: null
        });
    }

    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) return
        mounted.current= true
        getCultivos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCultivos();  
        return () => {
            request.abort()};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ predioActual ]);

    

    return (
        <DetallePredioComponent 
        errors={errors}
        cultivos={cultivos}
        predio={predioActual} 
        handleOnClick={handleOnClick}/>
    );
}

export default DetallePredioContainer;