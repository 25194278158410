import React from 'react';
import { useStateValue } from '../../state';
import { Grid, Button, Transition } from 'semantic-ui-react';
import Predios from '../predios/PrediosContainer';
import Newsfeed from '../newsfeed/NewsfeedContainer';
import SensorContainer from '../sensor/SensorContainer';
import DetallesPredios from '../predios/detalle/DetallePredioContainer';
import BitacotaDetailContainer from '../bitacora/detail/BitacoraDetailContainer';
import DispositivoDetailContainer from '../dispositivos/detail/DispositivoDetailContainer';

const GeneralComponent = props => {

    const [{ 
        predioActual,
        sensorActual,
        appFocusLeft,
        appFocusRight,
        cultivoActual,
        bitacoraActual,
        dispositivoActual,
    }] = useStateValue(); 

    let returnButton = [
        <Transition.Group
        duration={1000}>
            <Button
            circular
            icon='arrow left'
            className='ml95pct bg-white'
            key={Math.random()}
            onClick={() => { props.onClickReturnPredios()}}/>
        </Transition.Group>
    ];

    return(
        <Grid
        columns={13}
        className='h-100'
        verticalAlign='middle'>
            <Grid.Row
            className='pb0'>
                <Grid.Column 
                width={1}/>
                <Grid.Column 
                width={4}
                className='h-100 flex justify-center'
                verticalAlign='top'
                >
                {
                    appFocusLeft === 'predios' ? (
                        <Predios/>
                    )
                    : null
                }
                </Grid.Column>
                <Grid.Column 
                width={4}
                className={appFocusRight === 'newsfeed' ? 'h-98 mt3' : null}>
                    {
                        appFocusRight === 'newsfeed' ? (returnButton) :
                        null
                    }
                </Grid.Column>
                <Grid.Column 
                computer={4} tablet={7}
                className='scroll-newsfeed' >
                    {
                        appFocusRight === 'sensor' ? (<SensorContainer sensor={sensorActual}/> ) :
                        appFocusRight === 'detallePredio' ? ( <DetallesPredios predio={predioActual}/> ) :
                        appFocusRight === 'bitacoraDetail' ? (<BitacotaDetailContainer bitacora={bitacoraActual}/> ) :
                        appFocusRight === 'dispositivo' ? (<DispositivoDetailContainer dispositivo={dispositivoActual}/> ) :
                        appFocusRight === 'newsfeed' || appFocusRight === 'bitacorasList' ? (<Newsfeed cultivo={cultivoActual}/> ) :
                        null
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

}

export default GeneralComponent;