import { useStateValue } from '../../state';
import MapContainer from '../map/MapContainer';
import Navbar from '../navbar/NavbarContainer';
import React, { useState, useEffect } from 'react';
import { Grid, Button, Popup } from 'semantic-ui-react';
import GeneralContainer from '../general/GeneralContainer';
import MenuContainer from '../intelligence/MenuContainer';

const HomeComponent = props => {

    const [{appFocus}] = useStateValue(); 
    const [content, setContent] = useState([]);

    let homeContent = [];

    const checkHomeContent = () => {
        switch (appFocus) {
            case 'predios':
                homeContent = [(<GeneralContainer key={Math.random()}/>)];
                break;
            case 'inteligencia':
                homeContent = [(<MenuContainer key={Math.random()}/>)];
                break;
            default:
                break;
        }
        setContent(homeContent);
    }

    useEffect(() => {
        checkHomeContent();
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            homeContent = [];
            setContent(homeContent);
        };
    }, [appFocus]);

    return (
        <div className='h-100'>
            { appFocus === 'predios' ? ( <MapContainer /> ) : null }
            { appFocus === 'predios' ? (
                <Popup
                content={props.mapMessage}
                trigger={
                    <Button 
                        circular
                        icon='map'
                        color='green'
                        className='btn-focus-map'
                        onClick={() => {props.toggleFocusMap()}}
                    />
                }/>
            ) : null }
            <Grid columns={16} className='h-100' verticalAlign='middle'>
                <Grid.Row className={ appFocus === 'predios' ? 'pb0 bg-gradient' : 'pb0' }>
                    <Grid.Column className='h-100' verticalAlign='top'>
                        <Navbar active='predios'/>
                    </Grid.Column>
                    <Grid.Column width={15} className="content-container">
                        {content}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default HomeComponent;