import ls from 'local-storage';
import {useFetch} from 'use-http';
import { navigate } from "@reach/router";
import { useStateValue } from '../../state';
import NavbarComponent from './NavbarComponent';
import React, { useState, useRef,useEffect } from 'react';

const NavbarContainer = props => { 

    const [{ loggedUser }, dispatch] = useStateValue();
    
    const [showUser, setShowUser] = useState(false);
    const [profilePicture, setProfilePicture] = useState('/img/usuario.svg');

    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});

    const getUserData = async () => {
        const dataUser = await request.get('/user_logged');
        if (typeof dataUser !== 'undefined') {
            if (dataUser.hasOwnProperty('curl')) {
                setProfilePicture(dataUser.curl);                
            }
            dispatch({
                type: 'setLoggedUser',
                newLoggedUser: dataUser
            });
        }
    }

    const emailContactHandler = () => {
        window.open('mailto: soporte@biobot.farm', '_self');
    }

    const policyHandler = () => {
        window.open('/docs/politica-privacidad.pdf');
    }    

    const closeSessionHandler = () => {
        ls.remove('auth');
        dispatch({
            type: 'setLoggedUser',
            newLoggedUser: null
        });
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: null
        });
        dispatch({
            type: 'setAppFocusLeft',
            newAppFocusLeft: 'predios'
        });
        navigate('/login');
    }

    const clickPrediosHandler = () => {
        dispatch({
            type: 'setAppFocusLeft',
            newAppFocusLeft: 'predios'
        });
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: null
        });
        dispatch({
            type: 'setAppFocus',
            newAppFocus: 'predios'
        });
    }

    const clickIntelligenceHandler = () => {
        dispatch({
            type: 'setAppFocus',
            newAppFocus: 'inteligencia'
        });
    }

    const showUserHandler = ( state ) => {
        setShowUser(state);
    }

    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) return
      mounted.current= true
      getUserData();
    })

    return (
        <NavbarComponent 
        user={loggedUser}
        showUser={showUser}
        active={props.active}
        onPolicy={policyHandler}
        loading={request.loading}
        profilePicture={profilePicture}
        onClickShowUser={showUserHandler}
        onEmailContact={emailContactHandler}
        onClickPredios={clickPrediosHandler}
        onCloseSession={closeSessionHandler}
        onClickIntelligence={clickIntelligenceHandler}/>
    )
}

export default NavbarContainer