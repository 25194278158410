import React from 'react';
import LoadingComponent from './LoadingComponent';

const LoadingContainer = props => {
    
    return(
        <LoadingComponent/>
    );
}

export default LoadingContainer;
