import React, {useState} from 'react';
import {useFetch} from 'use-http';
import jwtDecode from 'jwt-decode';
import ls from 'local-storage';
import { Redirect } from '@reach/router';
import AcceptGuestInvitationComponent from './AcceptGuestInvitationComponent';
import { ERROR_GLOBAL_PASSWORD_MATCH, ERROR_GLOBAL_UNDEFINED } from '../../common/consts';

const AcceptGuestInvitationContainer = props => {
  
  let tokenDecoded = {}
  let itFailed = false;

  const [errors, setErrors] = useState();
  const [sended, setSended] = useState(false);

  const titleCase = str => {
    return str.toLowerCase().split(' ').map( word => {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }
  
  ls.remove('auth');
  const req = useFetch({
      headers: {
          'Authorization' : props.token
      }
  });

  try {
    tokenDecoded = jwtDecode(props.token);
  } catch {
    itFailed = true;
  }

  if (itFailed) {

    return (
      <Redirect noThrow to="/" />
    );

  } else {

    const onSubmit = formFields => {
      if (formFields.password === formFields.passwordConfirm) {

        let data = new FormData()
        
        if (typeof formFields.name !== 'undefined') {
          data.set('name', titleCase(formFields.name));          
        }

        if (typeof formFields.phone !== 'undefined') {
          data.set('telefono', formFields.phone);
        }

        data.set('password', formFields.password);

        req.post('/accept_invitation_guest',data).then( res => {
            if (res  === undefined) {
                setErrors(ERROR_GLOBAL_UNDEFINED);
            } else if (res.respuesta_id && res.respuesta_id !== '00') {
                setErrors(res.mensaje);
            } else {
                setSended(true);
            }
        });
      } else {
        setErrors(ERROR_GLOBAL_PASSWORD_MATCH);
      }
    }

    return (
        <AcceptGuestInvitationComponent user={tokenDecoded} onSubmit={onSubmit} errors={errors} sended={sended} load={req.loading}/>
    );

  }

}

export default AcceptGuestInvitationContainer;