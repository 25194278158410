import 'moment/locale/es';
import moment from 'moment';
import ls from 'local-storage';
import useFetch from 'use-http';
import { useStateValue } from '../../../state';
import React, { useState, useEffect, useRef } from 'react';
import { ERROR_GLOBAL_UNDEFINED } from '../../common/consts';
import useMapPainter from '../../common/hooks/useMapPainter';
import BitacoraDetailComponent from './BitacoraDetailComponent';
import usePolygonManager from '../../common/hooks/usePolygonManager';

moment.locale('es');

const BitacoraDetailContainer = props => {

    let bitacoraQuery;
    const [errors, setErrors] = useState();
    const [bitacora, setBitacora] = useState();
    const [showUser, setShowUser] = useState(false);
    const [paintMarker, paintPolygon] = useMapPainter();
    const [header, setHeader] = useState({
        header: null,
        img: null,
        color: null
    });
    const [
        // eslint-disable-next-line
        objectToArray, 
        getPolygonCenter, 
        polylineToCoords
    ] = usePolygonManager();

    const [{ cultivoActual, bitacoraActual, predioActual }, dispatch] = useStateValue();    

    let initDate = escape(moment(bitacoraActual.initDate).format('YYYY-MM-DD H:M:S'));
    let endDate = null;
    if (bitacoraActual.endDate) {
        endDate = escape(moment(bitacoraActual.endDate).format('YYYY-MM-DD H:M:S'));
    }
    
    switch(bitacoraActual.type){
        case 'Actividad':
            bitacoraQuery = '/cultivo/'+cultivoActual.id+'/bitacora/actividades?inicio_actividad='+initDate+'&bitacora_id='+bitacoraActual.id;
            if (endDate !==  null) bitacoraQuery = bitacoraQuery + '&fin_actividad=' + endDate;
            break;
        case 'Aplicacion Producto':
            bitacoraQuery = '/cultivo/'+cultivoActual.id+'/bitacora/productos?fecha_inicio='+initDate+'&bitacora_id='+bitacoraActual.id;
            if (endDate !==  null) bitacoraQuery = bitacoraQuery + '&fecha_final=' + endDate;
            break;
        case 'Sintomas':
            bitacoraQuery = '/cultivo/'+cultivoActual.id+'/bitacora/sintomas?fecha_inicio='+initDate+'&bitacora_id='+bitacoraActual.id;
            if (endDate !==  null) bitacoraQuery = bitacoraQuery + '&fecha_final=' + endDate;
            else bitacoraQuery = bitacoraQuery + '&fecha_final=' + escape(moment().format('YYYY-MM-DD H:M:S')); //TODO: Fix temporal, recordar a Chato arreglar esta ruta
            break;
        case 'Enfermedad':
            bitacoraQuery = '/cultivo/'+cultivoActual.id+'/bitacora/enfermedades?fecha_inicio='+initDate+'&bitacora_id='+bitacoraActual.id;
            if (endDate !==  null) bitacoraQuery = bitacoraQuery + '&fecha_final=' + endDate;
            else bitacoraQuery = bitacoraQuery + '&fecha_final=' + escape(moment().format('YYYY-MM-DD H:M:S'));
            break;
        case 'Cosecha':
            bitacoraQuery = '/cultivo/'+cultivoActual.id+'/bitacora/cosecha?inicio_cosecha='+initDate+'&bitacora_id='+bitacoraActual.id;
            break;
        default:
            break;
    } 
    
    const [request, response] = useFetch({headers: {'Authorization': ls.get('auth')}});
    const getBitacoraDetails = async () => {
        const dataBitacora = await request.get(bitacoraQuery);
        if (dataBitacora === undefined || dataBitacora.respuesta_id === '01' || !response.ok) {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            setBitacora(dataBitacora);
            paintMap(dataBitacora);
        } 
    }

    const personalizeHeader = () => {
        let imgName = bitacoraActual.type.toLowerCase() + '.svg';
        let type = bitacoraActual.type.split(' ');
        let color;

        if (type.length >= 2) {
            imgName = type[1].toLowerCase() + '.svg';
        }

        switch (bitacoraActual.type) {
            case 'Aplicacion Producto':
                color = 'teal';
                break;
            case 'Actividad':
                color = 'blue';
                break;
            case 'Sintomas':
                color = 'yellow';
                break;
            case 'Enfermedad':
                color = 'red';
                break;
            case 'Cosecha':
                color = 'orange';
                break;
            default:
                break;
        }

        setHeader({
            header: bitacoraActual.type.toUpperCase(),
            img: imgName,
            color: color,
        });
        
    }

    const paintMap = ( bitacora ) => {
        let coords;
        let center;

        switch (bitacoraActual.type) {
            case 'Aplicacion Producto':
                coords = polylineToCoords(bitacora.area_aplicacion);
                center = getPolygonCenter(JSON.parse(bitacora.area_aplicacion));
                paintMarker('/img/bitacoras/producto.svg', center[0], center[1], true, null);
                paintPolygon('#40dfc8', '#40dfc8', coords, true);
                break;
            case 'Actividad':
                coords = polylineToCoords(bitacora.area_actividad);
                center = getPolygonCenter(JSON.parse(bitacora.area_actividad));
                paintMarker('/img/bitacoras/actividad.svg', center[0], center[1], true, null);
                paintPolygon('#4b81ed', '#4b81ed', coords, true);
                break;
            case 'Sintomas':
                coords = polylineToCoords(bitacora.localizacion);
                center = getPolygonCenter(JSON.parse(bitacora.localizacion));
                paintMarker('/img/bitacoras/sintomas.svg', center[0], center[1], true, null);
                paintPolygon('#f2bb00', '#f2bb00', coords, true);
                break;
            case 'Enfermedad':
                coords = polylineToCoords(bitacora.localizacion);
                center = getPolygonCenter(JSON.parse(bitacora.localizacion));
                paintMarker('/img/bitacoras/enfermedad.svg', center[0], center[1], true, null);
                paintPolygon('#f32a05', '#f32a05', coords, true);
                break;
            case 'Cosecha':
                coords = polylineToCoords(bitacora.area_cosecha);
                center = getPolygonCenter(JSON.parse(bitacora.area_cosecha));
                paintMarker('/img/bitacoras/cosecha.svg', center[0], center[1], true, null);
                paintPolygon('#ff8c1d', '#ff8c1d', coords, true);
                break;
            default:
                break;
        }
    }
    
    const onClickReturn = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'bitacorasList'
        });
        dispatch({
            type: 'setBitacoraActual',
            newBitacoraActual: null
        });
    }

    const emailContactHandler = ( email ) => {
        window.open('mailto:'+ email
        +'?subject=Registro en '+cultivoActual.nombre_cultivo+' - biobot.farm'
        +'&body=En '+bitacoraActual.name, '_self');
    }

    const phoneContactHandler = ( phone ) => { 
        window.open('tel:'+ phone, '_self');
    }

    const showUserHandler = ( state ) => {
        setShowUser(state);
    }

    const mounted = useRef(false);
    useEffect(() => {
      if (mounted.current) return
      mounted.current= true
      getBitacoraDetails();
      personalizeHeader();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return(
        <BitacoraDetailComponent 
        errors={errors}
        header={header}
        bitacora={bitacora}
        showUser={showUser}
        predio={predioActual}
        cultivo={cultivoActual}
        onReturn={onClickReturn}
        onShowUser={showUserHandler}
        bitacoraActual={bitacoraActual}
        onPhoneContact={phoneContactHandler}
        onEmailContact={emailContactHandler}/>
    );   

}

export default BitacoraDetailContainer;