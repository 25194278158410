import React from 'react';
import '../common/css/floatForm.css';
import bitacorasCards from './bitacorasCards';
import { Card, Image, Button } from 'semantic-ui-react';

const BitacoraComponent = props => {
    
    let cartasBitacoras = [];
    let emptyCounter = 0;

    if (!props.errors) {
        cartasBitacoras.push(bitacorasCards('actividades', props, props.onClick, false));
        cartasBitacoras.push(bitacorasCards('producto', props, props.onClick, false));
        cartasBitacoras.push(bitacorasCards('enfermedad', props, props.onClick, false));
        cartasBitacoras.push(bitacorasCards('sintoma', props, props.onClick, false));
        cartasBitacoras.push(bitacorasCards('cosecha', props, props.onClick, false));        
    }     

    cartasBitacoras.forEach( element => {
        if (element.length === 0) emptyCounter++;
    });
    
    return (
        <div>
            {
                !props.empty ? (
                    <Card
                    fluid
                    className="center pa1 pt2 flex items-center">
                        { emptyCounter === 5 ? (
                            <div
                                className='bitacoras-list tc'>
                                <Image
                                size='small'
                                className='center mt3 mb3'
                                src='/img/placeholder/bitacoras.svg' />
                                <span
                                className='fw6 f4 dib'>
                                    No tienes registros recientes
                                </span>
                            </div>
                        ) : (
                            cartasBitacoras
                        )}
                        <Button
                        onClick={props.onClick}
                        className='float-form-button w-80 ma3'>
                            Ver todos los registros
                        </Button>
                    </Card>
                )
                    : null
            }
        </div>
    );

}

export default BitacoraComponent;