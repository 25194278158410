import React, {useEffect} from 'react'
import useForm from 'react-hook-form'
import { Grid, Message, Card, Input, Button, Form, Image } from 'semantic-ui-react'
import { REGEX_EMAIL_VALIDATION, MIN_FORMS_PASSWORD_LENGHT } from '../common/consts';
import '../common/css/floatForm.css'
import { Link } from '@reach/router'

const LoginComponent = props => {

    const { register, handleSubmit, setValue, errors } = useForm();

    const handleFormChange = type => event => {
        setValue(type, event.target.value)
    }

    useEffect(()=> {
        register({name: 'email'}, {required: true, pattern: REGEX_EMAIL_VALIDATION})
        register({name: 'password'}, {required: true, minLength: {value: 6, message: MIN_FORMS_PASSWORD_LENGHT}})
    }, [register])

    return(
        <Grid centered columns={4} verticalAlign='middle' className="h-100">
            <Grid.Row>
                <Grid.Column computer={5} mobile={14} tablet={8} textAlign="center">
                    <Card centered fluid raised={true} className='card-float-form'>
                        <Card.Content >
                            <Image src='img/logo.svg' size='small' className='pa4'/>
                            { (props.errors !== undefined) ? (
                                    <Message negative>
                                        <Message.Header>{props.errors}</Message.Header>
                                    </Message>
                                    )
                                    : null
                            }                                                        
                            { (errors.password) ? (
                                    <Message negative>
                                        <Message.Header>{errors.password && errors.password.message}</Message.Header>
                                    </Message>
                                    )
                                : null
                            }
                            <Form onSubmit={handleSubmit(props.onSubmit)} className='pt3'>
                                <Form.Field>
                                    <Input icon='user' type="email" name="email" placeholder="Correo electrónico" 
                                        className='float-form-input' size="big" onChange={handleFormChange('email')} />
                                </Form.Field>
                                <Form.Field>
                                    <Input icon='lock' type="password" name="password" placeholder="Contraseña"
                                        className='float-form-input'size="big" onChange={handleFormChange('password')} />
                                </Form.Field>
                                <Form.Field className='pt0'>
                                    <Link className='float-form-link' to='/forgot'>¿Olvidaste tu contraseña?</Link>
                                </Form.Field>
                                <Form.Field className='pt3'>
                                    <Button fluid type='submit' content='Iniciar sesión' size="big" className='float-form-button'/>
                                </Form.Field>
                                <Form.Field className='pt0'>
                                    <Link className='float-form-link' to='/signup'>¿No tienes cuenta? Regístrate aquí</Link>
                                </Form.Field>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default LoginComponent;