import React, { useState, useEffect } from 'react';
import 'moment/locale/es';
import moment from 'moment';
import ls from 'local-storage';
import useFetch from 'use-http';

import usePapa from '../common/hooks/usePapa';
import { useStateValue } from '../../state';
import IntelligenceComponent from './IntelligenceComponent';
import LoadingContainer from '../loading/LoadingContainer';
import ErrorContainer from '../errors/ErrorContainer';

const IntelligenceContainer = props => {

    const [downloadCSV] = usePapa();
    const [city, setCity] = useState([]);
    const [query, setQuery] = useState();
    const [users, setUsers] = useState([]);
    const [types, setTypes] = useState([]);
    const [crops, setCrops] = useState([]);
    const [state, setState] = useState([]);
    const [newLinks, setNewLinks] = useState([]);    
    const [tableData, setTableData] = useState();

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState (
        new Date(fromDate.getTime() + 7 * 24 * 60 * 60 * 1000)
    );

    const [userFilter, setUserFilter] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const [cropFilter, setCropFilter] = useState(null);
    const [stateFilter, setStateFilter] = useState(null);
    const [cityFilter, setCityFilter] = useState(null)
    const [activeFilters, setActiveFilters] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    // eslint-disable-next-line no-unused-vars
    const [{ inteligenciaFocus }, dispatchFocus] = useStateValue();
    const [request, response] = useFetch({headers: {'Authorization': ls.get('auth')}});

    const getTableData = async() => {
        let formattedFromDate = moment(fromDate).format('YYYY-MM-DD H:M:S');
        let formattedToDate = moment(toDate).format('YYYY-MM-DD H:M:S');

        let temporalQuery = '/business/slave/registros?pagina=' 
            + currentPage + '&por_pagina=10&fecha_creacion_minima=' 
            + formattedFromDate + '&fecha_creacion_maxima=' 
            + formattedToDate;

        let filters = {};

        if(userFilter) {
            temporalQuery += '&user=' + userFilter.id;
            filters.user = userFilter.name;
        }
        if(typeFilter) {
            temporalQuery += '&tipo_registro=' + typeFilter;
            filters.type = typeFilter;
        } 
        if(cropFilter) {
            temporalQuery += '&tipo_cultivo=' + cropFilter;
            filters.crop = cropFilter;
        }
        if(stateFilter) {
            temporalQuery += '&estado=' + stateFilter;
            filters.state = stateFilter;
        }
        if(cityFilter) {
            temporalQuery += '&ciudad=' + cityFilter;
            filters.city = cityFilter;
        }

        setActiveFilters(filters);
        setQuery(temporalQuery);

        const initialData = await request.get(temporalQuery);
        if(response.ok) {
            setTotalPages(initialData.total_paginas);

            const tableData = formatSlavesLog(initialData);
            setTableData(tableData);      
        }
    }

    const getDropdownInfo = async() => {
            
        const dropdownUsers = await request.get('/business/slave/users');
        if(response.ok) setUsers(formatDataDropDownById(dropdownUsers, 'name'));

        const dropdownCrops = await request.get('/business/slave/tipo_cultivos');
        if(response.ok) setCrops(formatDataDropDownByName(dropdownCrops, 'nombre'));   

        const dropdownState = await request.get('/business/slave/location?location_by=estado');
        if(response.ok) setState(formatDataDropDownByName(dropdownState, 'estado'));

        const dropdownCity = await request.get('/business/slave/location?location_by=ciudad');
        if(response.ok) setCity(formatDataDropDownByName(dropdownCity, 'ciudad'));

        const dropdownTypes = [
            { name: 'Actividad' },
            { name: 'Aplicacion Producto' },
            { name: 'Cosecha' },
            { name: 'Enfermedad' },
            { name: 'Sintoma'}
        ];
        setTypes(formatDataDropDownByName(dropdownTypes, 'name'));
    }

    /*
        Valida que las fechas sean útiles para generar su diferencia
        fecha_inicio y fecha_final pueden contener "None" o "0000-00-00 00:00:00"
    */
    function isDateValid(fecha_inicio, fecha_final) {
        if((fecha_inicio === "None" || fecha_inicio === "0000-00-00 00:00:00")
        || (fecha_final === "None" || fecha_final === "0000-00-00 00:00:00")
        || (fecha_inicio === null)
        || (fecha_final === null)
        || (moment(fecha_final).isSameOrBefore(moment(fecha_inicio)))) {
            return false;
        } else {
            return true;
        }
    }

    // Genera la diferencia de tiempo entre las fechas después de haber sido validadas
    function generateTimeDifference(fecha_inicio, fecha_final) {

        let initDate = moment(fecha_inicio);
        let endDate = moment(fecha_final);

        let diffInDays = endDate.diff(initDate, 'days');
        let diffInHours = endDate.diff(initDate, 'hours');
        let remainingHours;

        if(diffInDays > 0) {
            remainingHours = diffInHours - (diffInDays * 24);

            if(remainingHours === 0) {
                return diffInDays + ' días';
            } else {
                if(remainingHours === 1) return diffInDays + ' días ' + remainingHours + ' hora';

                return diffInDays + ' días ' + remainingHours + ' horas';
            }
        } else {
            return diffInHours + ' horas';
        }
    }

    /*
        Las fechas vienen en formato YYYY-MM-DD HH:MM:SS
        Remueve la hora de la fecha del parámetro (todo lo que esté después del espacio)
    */
    function formatDate(date) {

        let createdAt = moment(date).format('YYYY-MM-DD HH:MM:SS');
        let newDate = createdAt.substring(0, createdAt.indexOf(' '));

        return newDate;
    }

    // Función para cuando se requiere el id en la query principal
    const formatDataDropDownById = ( data, field ) => {
        
        let formatedData = [];
        if (typeof data !== 'undefined') {

            data.forEach(element => {

                let value = {
                    id: element.id,
                    name: element[field]
                }

                formatedData.push({
                    key: element[field],
                    text: element[field],
                    value: value
                });
            });
            let cuttedData = removeDuplicates(formatedData, 'text');
            return cuttedData;
        }
    }

    // Función para cuando se requiere el nombre en la query principal
    const formatDataDropDownByName = ( data, field ) => {
        
        let formatedData = [];
            data.forEach(element => {
                formatedData.push({
                    key: element[field],
                    text: element[field],
                    value: element[field]
                });
            });
        let cuttedData = removeDuplicates(formatedData, 'text');
        return cuttedData;
    }

    const formatSlavesLog = (dataAPI) => {
        let formattedData = [];
        dataAPI.resultado.forEach(element => {
    
            let fechaCreacion = element.created_at;
            let fechaInicio = element.fecha_inicio;
            let fechaFinal = element.fecha_final;
            let date = formatDate(fechaCreacion);
            let duration;
            isDateValid(fechaInicio, fechaFinal)
                ? (duration = generateTimeDifference(fechaInicio, fechaFinal)) 
                :  duration = 'N/A';
            let user = element.name;
            let mail = element.email;
            let registry = element.tipo_bitacora;
            let notes;
            element.notas === null ? notes = 'N/A' : notes = element.notas;
            let crop = element.nombre;
            let state;
            element.estado === null ? state = 'N/A' : state = element.estado;
            let city;
            element.ciudad === null ? city = 'N/A' : city = element.ciudad;    

            let docs = [];
            element.archivos.forEach(archivo => {
                docs.push(archivo.curl);
            });
        
            let row = {
                'date': date,
                'duration': duration,
                'user': user,
                'mail': mail,
                'registry': registry,
                'notes': notes,
                'docs': docs,
                'crop': crop,
                'state': state,
                'city': city
            };

            formattedData.push(row);
        }); 

        return formattedData;
    }

    const removeDuplicates = (array, key) => {
        let lookup = new Set();
        return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key])); 
    }    

    const exportToExcelHandler = async() => {

        let excelData = [];
        let exportQuery = query + '&is_export=true';
        const dataAPI = await request.get(exportQuery);
        
        if (typeof dataAPI !== 'undefined') {
            //Se le da un formato al JSON porque usePapa toma las keys como nombre de columnas.
            excelData = formatSlavesLog(dataAPI);
            excelData.forEach(bitacora => {
                bitacora.docs.forEach((element) => {
                    separateLinks(element);
                    bitacora.docs = newLinks;
                });
                setNewLinks([]);
            });
            downloadCSV(excelData, `Reporte del ${moment(new Date()).format('D MMMM YYYY')}.csv`);
        }

    }
      
    let separateLinks = (link) => {
        let oldLinks = newLinks;
        oldLinks.push(` ${link} `);
        setNewLinks(oldLinks);
    }

    /*
    ** Funciones que controlan el contenido de las tablas en relación a las fechas
    */
    const changeFromDateHandler = (newDate) => {
        setFromDate(newDate);
    }

    const changeToDateHandler = (newDate) => {
        setToDate(newDate);
    }

    /*
    ** Funciones que actualizan el estado de los filtros
    */
    const byUserHandler = (event, dropdownInfo) => {
        setUserFilter(dropdownInfo.value);
    }

    const byTypeHandler = (event, dropdownInfo) => {
        setTypeFilter(dropdownInfo.value);
    }

    const byCropHandler = (event, dropdownInfo) => {
        setCropFilter(dropdownInfo.value);
    }

    const byStateHandler = (event, dropdownInfo) => {
        setStateFilter(dropdownInfo.value);
    }

    const byCityHandler = (event, dropdownInfo) => {
        setCityFilter(dropdownInfo.value);
    }

    const resetUserFilter = () =>  {
        setUserFilter(null);
    }

    const resetTypeFilter = () =>  {
        setTypeFilter(null);
    }

    const resetCropFilter = () =>  {
        setCropFilter(null);
    }

    const resetStateFilter = () => {
        setStateFilter(null);
    }

    const resetCityFilter = () => {
        setCityFilter(null);
    }

    const resetFilters = () => {
        setUserFilter(null);
        setTypeFilter(null);
        setCropFilter(null);
        setStateFilter(null);
        setCityFilter(null);
        setActiveFilters(null);
    }

    /* 
    ** Funciones que controlan los eventos de paginación
    */
    const changeCurrentPageHandler = (event, paginationInfo) => {
        setCurrentPage(paginationInfo.activePage);
    }

    const resetPagination = () => {
        setCurrentPage(1);
    }

    /*
    ** Funciones que controlan los estados globales
    */
    const returnHandler = () => {
        dispatchFocus({
            type: 'setInteligenciaFocus',
            newInteligenciaFocus: null
        });
    }

    useEffect(() => {
        getDropdownInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        resetFilters();
        resetPagination();
        getTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate]);

    useEffect(() => {
        getTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        setActiveFilters([]);
        resetPagination();
        getTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFilter, typeFilter, cropFilter, stateFilter, cityFilter]);

    return (
        <>
            {request.error && <ErrorContainer error={request.error}/>}
            {request.loading && <LoadingContainer/>}
            {
                <IntelligenceComponent
                    toDate = {toDate}
                    dropCity = {city}
                    data = {tableData}
                    dropUsers = {users}
                    dropTypes = {types}
                    dropCrops = {crops}
                    dropState = {state}
                    fromDate = {fromDate}
                    onExportToExcel = {exportToExcelHandler}
                    onChangeFromDate = {() => changeFromDateHandler}
                    onChangeToDate = {() => changeToDateHandler}
                    onChangeCurrentPage = {() => changeCurrentPageHandler}
                    currentPage = {currentPage}
                    totalPages = {totalPages}
                    onChangeUser = {() => byUserHandler}
                    onChangeType = {() => byTypeHandler}
                    onChangeCrop = {() => byCropHandler}
                    onChangeState = {() => byStateHandler}
                    onChangeCity = {() => byCityHandler}
                    activeFilters = {activeFilters}
                    resetUserFilter = {() => resetUserFilter}
                    resetTypeFilter = {() => resetTypeFilter}
                    resetCropFilter = {() => resetCropFilter}
                    resetStateFilter = {() => resetStateFilter}
                    resetCityFilter = {() => resetCityFilter}
                    selectReturn = {() => returnHandler}
                />
            }
        </>
    )
}

export default IntelligenceContainer;