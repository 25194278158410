import React, {useState, useRef, useEffect, useCallback} from 'react';
import ForecastComponent from './ForecastComponent';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const ForecastContainer = props => {
    
    const [daily, setDaily] = useState({
        data: []
    });

    const [today, setToday] = useState({
        icon: null,
        max: null,
        min: null,
        temp: null,
        prob: null
    });

    const onSetToday = useCallback(
        () => {
            setToday({
                icon: props.weather.currently.icon,
                max: Math.round(props.weather.currently.maxima) + '°',
                min: Math.round(props.weather.currently.minima) + '°',
                temp: Math.round(props.weather.currently.temperatura),
                prob: Math.round(props.weather.currently.probabilidad * 100) + '%',
            });
        }, [props.weather.currently.icon, props.weather.currently.maxima, props.weather.currently.minima,
            props.weather.currently.temperatura, props.weather.currently.probabilidad]
    )

    const onSetDaily = newDaily => {
        setDaily({
            data: newDaily
        })
    }

    const mounted = useRef(false);

    useEffect(() => {
      if (mounted.current) return
        mounted.current= true
        onSetToday()
        let forecast = props.weather.daily.slice(1,6);
        let newDaily = []
        forecast.forEach((element)=> {
            let data = {
                date: moment(element.fecha).format('dddd'),
                icon: element.icon,
                max: Math.round(element.maxima) + '°',
                min: Math.round(element.minima) + '°',
                prob: Math.round(element.probabilidad * 100) + '%',
            };
            newDaily.push(data)
        });
        let oldState = daily.data;
        let newState = oldState.concat(newDaily)
        onSetDaily(newState);
    },[props, daily.data, onSetToday])

    return(
        <ForecastComponent today={today} daily={daily.data}/>
    );

}

export default ForecastContainer;
