import '../common/css/predios.css';
import ellipsis from 'text-ellipsis';
import { useStateValue } from '../../state';
import LazyImage from 'react-lazy-progressive-image';
import React, { useState, useCallback, useRef } from 'react';
import { Card, Image, Button, Popup, Transition, List, Icon, Input } from 'semantic-ui-react';

const PrediosComponent = props => {    

    // eslint-disable-next-line
    const [{ mapMarkers }, dispatch] = useStateValue();
    
    let [observerCount, setObserverCount] = useState(0);
    const [listPredios, setListPredios] = useState(false);

    const observer = useRef();

    let cartasPredios = [];
    let predios = props.predios.data;

    const lastPredioElementRef = useCallback(node => {
        if (props.loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver( entries => {
            if (entries[0].isIntersecting) {
                setObserverCount(observerCount++);
                if (observerCount >= 2 && !props.isDone && props.isOpen) {
                    props.onShowMore();
                }
            }
        });
        if (node) observer.current.observe(node);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loading, props.isDone, props.isOpen]);

    const handleSearchChange = event => {
        if (event.target.value !== '') {
            props.onSearchPredio(event.target.value);
            setListPredios(true);
        } else {
            setListPredios(false);
        }
    }
 
    const handleOnShow = () => {
        setListPredios(!listPredios);
        if (listPredios === false) {
            dispatch({
                type: 'setPredioActual',
                newPredio: null
            });
        }
    }

    const showPredios = () => {
        if (listPredios) {
            for (let index = 0; index < predios.length; index++) {
                const element = predios[index];
                cartasPredios.push(
                    <div
                    key={element.id}
                    className='mb3 pointer'
                    onClick={() => { props.handleOnClick(element) }}>
                        <Card>
                            <LazyImage
                            src={ element.mapa_curl.curl ? (element.mapa_curl.curl) : '/img/predio-placeholder.png' }
                            placeholder='/img/placeholder-card.png'>
                                {src => <Image src={src} alt={element.nombre} />}
                            </LazyImage>
                            <Popup
                            on='click'
                            content='¡Predio copiado al portapapeles!'
                            trigger={
                                <Button
                                compact
                                circular
                                size='mini'
                                floated='right'
                                className='btn-share'
                                icon='share alternate'
                                onClick={() => { props.onShareHandler(element) }}/>
                            } />
                            <div 
                            className='card-tag'
                            ref={ (predios.length === index + 1) ? lastPredioElementRef : null } >
                                <Card.Header
                                className='white pt1 pl1 b'>
                                    {ellipsis(element.nombre,20)}
                                </Card.Header>
                                <Card.Meta>
                                    <span 
                                    className='white pl1 pt1 f6'>
                                        {element.creador.name}
                                    </span>
                                </Card.Meta>
                            </div>
                        </Card>
                    </div>
                );
            }
        } else {
            cartasPredios = [];
        }
    }

    const emptyPredios = () => {
        return (
            <div
            className='bitacoras-list tc'>
                <Image
                size='tiny'
                className='center mt3 mb3'
                src='/img/placeholder/cultivos.svg' />
                <span
                className='fw6 f4 dib'>
                    No tienes predios
                </span>
                <span
                className='dib mb3 f5'>
                    Crea uno desde nuestra app móvil
                </span>
            </div>
        );
    }


    predios[0] === undefined ? emptyPredios() : showPredios();

    return (
        <div 
        className='mt4'>

            <Card
            className='br4'>
                <Input
                transparent
                name='search'
                iconPosition='left'
                placeholder='Buscar predios'
                className='pa2-5 pl3 shadow-2'
                onChange={handleSearchChange.bind(this)}
                icon={<Icon
                    name='search'
                    className='left-1' />
                    }
                />
                <Button
                compact
                circular
                className='btn-noborder'
                onClick={ () => {
                    handleOnShow();
                }}
                icon={ () => {
                    if (listPredios) {
                        return <Icon name='angle up' />
                    } else {
                        return <Icon name='angle down' />
                    }
                }}
                />
                {
                    cartasPredios.length > 0 ? (
                        <Transition
                        duration={350}
                        onComplete={props.setIsOpen(true)}
                        >
                            <Transition.Group
                            divided
                            as={List}
                            size='huge'
                            duration={500}
                            className='ma3 mr2 pr2 mt0 mb0 list-cards list-predios'>
                                {cartasPredios}
                            </Transition.Group>
                        </Transition>
                        
                    ) : null
                }
            </Card>
        </div>

    )
}

export default PrediosComponent;
