import React from 'react';
import ellipsis from 'text-ellipsis';
import { Card, Image, Transition, Icon, Message } from 'semantic-ui-react';

const DispositivoDetailComponent = props => {

    let sensorCards = [];
    if (typeof props.sensores !== 'undefined') {        
        props.sensores.sensores.forEach((sensor) => {
            let imgName = sensor.tipo.toLowerCase().replace(/ /g,"-");
            sensorCards.push(
                <div
                key={sensor.tipo}
                className='mb3 pointer'
                onClick={() => { props.onClick(sensor) }}>
                    <Card
                    fluid
                    className='center'>
                        <Card.Content>
                            <div className='flex items-center'>
                                <Image
                                inline
                                className='w-16'
                                src={'/img/sensores/'+imgName+'.svg'}
                                onError={ ( e ) => { 
                                    e.target.onerror = null; 
                                    e.target.src= '/img/placeholder/sensor.svg';
                                }}/>
                                <div
                                className='flex flex-column'>
                                    <span 
                                    className='f5 fw5 black'>
                                        {ellipsis(sensor.tipo,17)}  
                                    </span>
                                    <span
                                    className='f7 gray'>
                                        {sensor.created_at}
                                    </span>
                                </div>
                                <div
                                className='ml-auto'>
                                    <span
                                    className="black mr2">
                                        {sensor.valor + sensor.unidad_base}
                                    </span>
                                    <Icon
                                        fitted
                                        size='small'
                                        color='black'
                                        name='chevron right'/>
                                </div>
                            </div>
                        </Card.Content>
                    </Card>
                </div>
            );
        });
    }


    return(
        <div>
            {
                props.errors ? (
                    <Message
                    icon
                    negative>
                        <Icon 
                        name='arrow left'
                        className='pointer'
                        onClick={() => (props.onReturn())}
                        />
                        <Message.Content>
                            {props.errors}
                        </Message.Content>
                    </Message>
                ) : typeof props.sensores !== 'undefined' ? (
                    <Transition.Group
                    duration={1000}
                    >
                        <Card className="center" fluid>
                            <Card.Content>
                                <div>
                                    <Image
                                    className='w-6 pointer'
                                    src='/img/arrow-left.svg'
                                    onClick={() => {props.onReturn()}}/>
                                    <p className="ml3 fw5 truncate w-70 dib v-mid">
                                        {props.sensores.nombre}
                                    </p>
                                </div>
                            </Card.Content>
                        </Card>
                        <p
                        className='white fw5'>
                        Sensores
                        </p>
                        {sensorCards}
                    </Transition.Group>
                ) : null
            }
        </div>
    );

}

export default DispositivoDetailComponent;