import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { navigate } from "@reach/router"

const Index = () => (
    <Grid centered columns={4} verticalAlign='middle' className="h-100">
        <Grid.Row>
            <Grid.Column computer={5} mobile={14} tablet={8} textAlign="left" >
                <Image centered src="img/logo.svg" size="small"/>
                <h1 className="tc">Bienvenido</h1>
                <p className="tc">Actualmente estamos trabajando para mejorar tu experencia, disculpa las molestias.</p>
                {/* eslint-disable-next-line */}
                <p className="tc"><a onClick={() => {navigate('/login')}}>Login</a> | <a onClick={()=>{navigate('/signup')}}>Signup</a> (v1.0)</p>
                <br/>
                <p className="tr"> -El equipo de <a href="https://biobot.farm/">biobot.farm</a> </p>
            </Grid.Column>
        </Grid.Row>
    </Grid>
        
)
export default Index;