import 'moment/locale/es';
import moment from 'moment';
import ls from 'local-storage';
import useFetch from 'use-http';
import { useStateValue } from '../../state';
import SensorComponent from './SensorComponent';
import { ERROR_GLOBAL_UNDEFINED} from '../common/consts';
import React, { useState, useEffect, useRef } from 'react';

moment.locale('es');

const SensorContainer = props => {

    const [{ sensorActual }, dispatch] = useStateValue();
    const [errors, setErrors] = useState();
    const [sensor, setSensor] = useState();
    const [startDate, setStartDate] = useState(new Date(sensorActual.created_at));
    const [endDate, setEndDate] = useState(new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000));

    const [request] = useFetch({headers: {'Authorization': ls.get('auth')}});
    const getSensor = async () => {

        let start = escape(moment(startDate).format('YYYY-MM-DD H:M:S'));
        let end = escape(moment(endDate).format('YYYY-MM-DD H:M:S'));

        const dataSensor = await request.get('/sensores/'+sensorActual.sensor_id+'/log?start_date='+start+'&end_date='+end);
        if (dataSensor === undefined || dataSensor.respuesta_id === '01') {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else {
            setSensor(dataSensor);
        }
    }

    const onReturnHandler = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'dispositivo'
        });
        dispatch({
            type: 'setSensorActual',
            newDispositivoActual: null
        });
    }

    const onChangeStartDate = ( date ) => {
        setStartDate(date);
    }

    const onChangeEndDate = ( date ) => {
        setEndDate(date);
    }

    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) return
        mounted.current= true
        getSensor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        getSensor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);
    
    return (
        <SensorComponent
        errors={errors}
        sensor={sensor}
        endDate={endDate}
        startDate={startDate}
        actual={sensorActual}
        onReturn={onReturnHandler}
        onChangeEndDate={onChangeEndDate}
        onChangeStartDate={onChangeStartDate}/>
    );
}

export default SensorContainer;