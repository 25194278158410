import Papa from 'papaparse';

/**
 * Custom Hook para el manejo de la libreria Papa Parse.
 */
const usePapa = () => {

    /**
     * Toma los datos de un arreglo de JSONs y permite bajar un CSV con esos datos.
     * @param {[]} data - Un arreglo de datos con los que se generará el CSV. 
     * @param {String} filename - Nombre del archivo que se descargará.
     */
    const downloadCSV = ( data, filename ) => {

        var csv = Papa.unparse(data);
        var blob = new Blob([csv]);
        
        if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, filename);
        else {
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
            a.download = filename;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
        }
    }

    return [
        downloadCSV
    ];
    
}

export default usePapa;