import React from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';

const DispositivosComponent = props => {

    let cartasDispositivos = [];
    const dispositivos = props.dispositivos;

    if (dispositivos.length > 0 ) {
        cartasDispositivos = dispositivos.map((disp) =>
        <div
        key={disp.nombre}
        className='mb3'
        onClick={() => {props.onClick(disp)}}>
            <Card
                fluid
                className='center pointer'>
                <Card.Content>
                    <div>
                        <Image
                            size='mini'
                            src='/img/botlog.svg'
                            className='w-30'
                        />
                        <p className="v-mid ml3 dib truncate w-75 mb0 black">{disp.nombre}</p>
                        <Icon
                            name="chevron right"
                            size="small"
                            fitted
                            color="black"
                        />
                    </div>
                </Card.Content>
            </Card>
        </div>
        );
    } else {
        cartasDispositivos.push(
            <Card
            fluid
            key={Math.random()}
            className='center pa1 pt2'>
                <div
                className='bitacoras-list tc'>
                    <Image
                    size='small'
                    className='center mt3 mb3'
                    src='/img/placeholder/dispositivos.svg' />
                    <span
                    className='fw6 f4 dib'>
                        No tienes dispositivos
                    </span>
                    <span
                    className='dib mb3 f5'>
                        Agrega uno a tu cultivo desde nuestra app móvil
                    </span>
                </div>
            </Card>
        );
    }
    
    return (
        <div>
            {cartasDispositivos}
        </div>
    );

}

export default DispositivosComponent;