import 'moment/locale/es';
import React from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker'
import { Card, Message, Image, Icon, Grid } from 'semantic-ui-react';
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

moment.locale('es');

const SensorComponent = props => {

    let chartData = [];

    if (typeof props.sensor !== 'undefined') {
        props.sensor.forEach(data => {
            chartData.push(
                {
                    fecha: moment(data.time).format('DD MMM YYYY - HH:mm:SS'),
                    valor: parseFloat(data.value)
                }
            );
        });
    }

    return (
        <div>
        { (typeof props.sensor !== 'undefined') ? (
            <div>
                <Card
                fluid
                className='center pa1 pt2 pb1'>
                    <Card.Content>
                        <div>
                            <Image
                            className='w-6 pointer'
                            src='/img/arrow-left.svg'
                            onClick={() => {props.onReturn()}}
                            />
                            <p
                            className='ml3 fw5 w-70 dib v-mid'>
                                {props.actual.tipo}
                            </p>
                        </div>
                    </Card.Content>
                </Card>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <span
                            className='white'>
                                Desde
                            </span>
                            <DatePicker
                            clearIcon={null}
                            calendarIcon={null}
                            value={props.startDate}
                            className='date-picker'
                            onChange={props.onChangeStartDate}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <span
                            className='white'>
                                Hasta
                            </span>
                            <DatePicker
                            clearIcon={null}
                            calendarIcon={null}
                            value={props.endDate} 
                            className='date-picker'
                            onChange={props.onChangeEndDate}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Card
                fluid
                className='center pa1 pt2 pb1'>
                    <Card.Content
                    className='pa0 overflow-x-hidden'>
                    </Card.Content>
                        <AreaChart
                        width={350}
                        height={220}
                        className='center ml--30px'
                        data={chartData}>
                            <defs>
                                <linearGradient 
                                id='colorSensor' 
                                x1='0' 
                                y1='0' 
                                x2='0' 
                                y2='1'>
                                <stop 
                                offset='5%'
                                stopOpacity={0.8}
                                stopColor='#00b54a'/>
                                <stop 
                                offset='95%' 
                                stopOpacity={0}
                                stopColor='#00b54a'/>
                                </linearGradient>
                            </defs>
                            <XAxis 
                            dataKey='fecha'/>
                            <YAxis
                            dataKey='valor' />
                            <Tooltip />
                            <Area 
                            dataKey='valor'
                            fillOpacity={1}
                            type='monotone' 
                            stroke='#00b54a' 
                            fill='url(#colorSensor)'/>
                        </AreaChart>
                </Card>
            </div>
            ) : props.errors ? (
                <Message
                icon
                negative>
                    <Icon 
                    name='arrow left'
                    className='pointer'
                    onClick={props.onReturn}
                    />
                    <Message.Content>
                        {props.errors}
                    </Message.Content>
                </Message>
            ) : null}
        </div>
    );

}

export default SensorComponent;