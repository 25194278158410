import '../common/css/bgmap.css';
import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const MapComponent = props => {

    const [mark, setMark] = useState([]);
    const [polygon, setPolygon] = useState([]);

    const mapOptions = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeId: 'hybrid'
    }

    const mapStyle = {
        height: '100vh',
        width: '94%',
        position: 'absolute',
        overflow: 'hidden',
        right: '0',
        top: '0'
    }
    
    useEffect(() => {
        setMark(props.markers);
        setPolygon(props.polygons);
        return () => {
            setMark([]);
            setPolygon([]);
        };
    }, [props.markers,
        props.polygons]);

    return (
        <LoadScript
        language='es'
        id='script-loader'
        loadingElement={<div></div>}
        preventGoogleFontsLoading={true}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
            <GoogleMap
            id='biobot-map'
            mapTypeId='hybrid'
            zoom={props.zoom}
            options={mapOptions}
            center={props.center}
            mapTypeControl={false}
            mapContainerStyle={mapStyle}>
                {mark}
                {polygon}
            </GoogleMap>
        </LoadScript>
    );
}

export default MapComponent;