import React from 'react';
import { useStateValue } from '../../state';
import { Card, Image, Transition } from 'semantic-ui-react';
import BitacoraContainer from '../bitacora/BitacoraContainer';
import ForecastContainer from '../forecast/ForecastContainer';
import DispositivosContainer from '../dispositivos/DispositivosContainer';
import BitacoraListContainer from '../bitacora/list/BitacoraListContainer';

const NewsfeedComponent = props => {

    const [{ appFocusRight }] = useStateValue();


    let normalfeed;
    if (typeof props.newsfeed === 'undefined') {
        normalfeed = null
    } else {
        normalfeed = (
            <div>
            <Card className="center" fluid>
                <Card.Content>
                    <div>
                        <Image
                            size='mini'
                            src='/img/cultivo-inactivo.svg'
                            className='w-30'
                        />
                        <p className="v-mid ml3 dib truncate w-70 black">{props.newsfeed.tipo_cultivo}</p>
                    </div>
                    <div className='mt3'>
                        <Image
                            size='mini'
                            src='/img/predio-verde-circulo.svg'
                            className='w-30'
                        />
                        <p className="v-mid ml3 dib truncate w-70 black">{props.newsfeed.predio}</p>
                    </div>
                </Card.Content>
            </Card>
            <ForecastContainer weather={props.newsfeed.clima}/>
            <div>
                <h2 className="white">Dispositivos</h2>
                <DispositivosContainer dispositivos={props.newsfeed.ultimo_dato_real} />
            </div>
            { typeof props.newsfeed.generales === 'undefined' ? null : (
                <div>
                    <div>
                        <h2 
                        className='white mt3 mb3'>
                            Bitácora
                        </h2>
                    </div>
                    <BitacoraContainer bitacora={props.newsfeed.generales.bitacoras} />
                </div>
            )}
            {/* <div>
                <h2 className="white ml1-4">Plan de insumos</h2>
                <Card
                    onClick={() => { }}
                    className="center"
                >
                    <Card.Content>
                        <Image
                            size='mini'
                            src='/img/plus.svg'
                            className='w-30'
                        />
                        <p className="v-mid ml3 dib truncate w-70 mb0 green">Crear plan de insumos</p>
                        <Icon
                            name="chevron right"
                            size="large"
                            color="green"
                            fitted
                        />
                    </Card.Content>
                </Card>
            </div> */}
            </div>
        );
    }

    return (
        <Transition.Group
        duration={1000}
        >
            {typeof props.newsfeed !== 'undefined' ? (
                <div className="ma2-5">
                    <Card className="center" fluid>
                        <Card.Content>
                            <div>
                                {
                                    (appFocusRight === 'bitacorasList') ?  (
                                        <Image
                                        className='w-6 pointer'
                                        src='/img/arrow-left.svg'
                                        onClick={() => {props.onClickReturn()}}
                                        />
                                    ) : (
                                        <Image
                                        size='mini'
                                        src='/img/cultivo.svg'
                                        className='w-30'
                                        />
                                    )
                                }
                                <p className="ml3 fw5 truncate w-70 dib v-mid">
                                    {
                                        (appFocusRight === 'bitacorasList') ? 'Bitacora de ' + props.newsfeed.nombre : props.newsfeed.nombre
                                    }
                                </p>
                            </div>
                        </Card.Content>
                    </Card>
                    {appFocusRight === 'bitacorasList' ? (
                        <BitacoraListContainer bitacoras={props.newsfeed.generales.bitacoras}/>
                    ) : (
                        normalfeed
                    )}
                </div>
            ) : null}
        </Transition.Group>
    )
}

export default NewsfeedComponent;