import ellipsis from 'text-ellipsis';
import DatePicker from 'react-date-picker';
import React, { useState, useEffect } from 'react';
import LazyImage from 'react-lazy-progressive-image';
import { Card, Button, Image, Modal, Pagination, Message } from 'semantic-ui-react';

const AnalysisComponent  = props => {
    
    const [prediosCards, setPrediosCards] = useState([]);
    const [analysisDocs, setAnalysisDocs] = useState([]);

    const successMessage = [
        <Message positive key={Math.random()}>
            <Message.Header>¡El documento se ha subido con éxito!</Message.Header>
        </Message>
    ];

    const failedMessage = [
        <Message negative key={Math.random()}>
            <Message.Header>Por favor intentalo más tarde, ha ocurrido un error con el documento</Message.Header>
        </Message>
    ];

    const generateCards = () => {
        let cards = [];

        props.cardsInfo.forEach(element =>  {

            let callbackInfo = {
                id: element.id,
                user: element.nombre_persona,
                predio: element.nombre_predio
            }

            cards.push(
                <Card key={Math.random()}>
                    <LazyImage
                    src={ element.map_curl ? (element.map_curl) : '/img/predio-placeholder.png' }
                    placeholder='/img/placeholder-card.png'>
                        {src => <Image src={src} />}
                    </LazyImage>
                    <div className='info-container'>
                        <h2>{ellipsis(element.nombre_predio, 20)}</h2>
                        <h3>{element.nombre_persona}</h3>
                    </div>
                    <div className="overlay">
                        <Button inverted value={callbackInfo} onClick={props.selectCrop()}>Seleccionar</Button>
                    </div>
                </Card>
            );
        });

        setPrediosCards(cards);
    }

    const generateDocs = () =>  {
        let docs = [];
        props.filesInfo.forEach(element => {

            docs.push(
                <Card key={Math.random()} className='doc-card'>
                    <div>
                        <Image src='/img/inteligencia/bar-chart-2.svg'></Image>
                        <p className='charcoal-grey'>{element.dayAndMonth}</p>
                        <p className='charcoal-grey'>{element.year}</p>
                    </div>
                    <div>
                        <Button as='a' href={element.curl} target='_blank'>
                            <Image inline className='dib mr3' src='/img/download.svg' />
                            <span>Descargar</span>
                        </Button>
                    </div>
                </Card>
            );

            setAnalysisDocs(docs);
        });
    }

    useEffect(() => {
        if(typeof props.cardsInfo !== 'undefined') {
            generateCards();
        }
        return () => {
            setPrediosCards([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cardsInfo]);

    useEffect(() => {
        if(typeof props.filesInfo !== 'undefined') {
            generateDocs();
        }
        return () => {
            setAnalysisDocs([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filesInfo]);
    
    return(
        <div className="analysis-container">
            <div>
                <Image src='/img/inteligencia/arrow-left.svg' className='dib' as='button' onClick={props.selectReturn()}></Image>
                <p className='dib charcoal-grey'>Regresar</p> 
            </div>
            <div>
                <Image src='/img/inteligencia/analisis.svg' className='mb3'></Image>   
                <div>
                    <h2 className='charcoal-grey'>Digitalización de análisis</h2>
                    <h3 className='charcoal-grey'>Selecciona un cultivo para ver su análisis</h3>
                </div>
            </div>
            <div>
                {prediosCards}
                <div className="pages-container">
                    { !props.totalPages 
                        ?
                        <div></div>
                        : 
                        <Pagination
                            boundaryRange={1}
                            ellipsisItem={null}
                            siblingRange={3}
                            firstItem={1}
                            lastItem={props.totalPages}
                            totalPages={props.totalPages}
                            activePage={props.currentPage}
                            onPageChange={props.changePage()}
                        />   
                        }
                </div>
            </div>
        {!props.selectedPredio
            ?
            <div className='placeholder'>
                <Image src='/img/inteligencia/analisis.svg'></Image>   
                <h2 className='charcoal-grey'>Análisis de cultivos</h2>
                <p className='charcoal-grey'>Selecciona un cultivo para que sus análisis aparezcan aquí</p>
            </div>
            :  
            <div className='docs-container'>
                <div>
                    <h3>Análisis de {props.selectedPredio}</h3>
                    <Button value={true} onClick={props.changeModalStatus()}>Subir análisis</Button>
                </div>
                <div>
                    {analysisDocs}
                </div>
            </div>
            }
            <Modal className='intelligence-modal' size='mini' open={props.modalStatus} onClose={props.changeModalStatus(false)}>
                <div>
                    <h2 className='charcoal-grey'>Subir análisis</h2>
                </div>
                <div>
                    <h3 className='charcoal-grey'>Cultivo seleccionado</h3>
                    <img src="/img/inteligencia/leaf1.png" alt="" className='dib mr3'/>
                    <div className='predio-selected dib'>
                        <p className='charcoal-grey fw5'>{props.selectedPredio}</p>
                        <p className='charcoal-grey fw2'>{props.user}</p>
                    </div>
                </div>
                <form onSubmit={props.onUploadDocument}>
                <div className='uploader'>
                    <h3 className='charcoal-grey'>Subir archivo</h3>
                    <div>
                        <Image src='/img/inteligencia/upload.svg' alt='upload'></Image>
                        <input id='files' type='file' accept='application/pdf' onChange={e => props.setFile(e.target.files[0])}/>
                        {props.uploadedFile === null 
                        ? 
                        <label for='files'>Seleccionar archivo</label>
                        : 
                        <label for='files'>{props.uploadedFile.name}</label>
                        }
                    </div>
                </div>
                <div className='date-selection'>
                    <h3 className='charcoal-grey'>Selecciona la fecha del análisis</h3>
                    <DatePicker className='mb4 date-picker-light-gray' onChange={props.onChangeExpeditionDate} value={props.expeditionDate}/>
                    <Button type='submit' className='submit'>Subir análisis</Button>
                </div>
                </form>
            { props.uploadStatus ? (successMessage) : null }
            { props.uploadStatus === false ? (failedMessage) : null }
            </Modal>
        </div>
    );
}

export default AnalysisComponent;