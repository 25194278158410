import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

/**
 * Esta función toma las bitacoras de un cultivo y las procesa para su sencillo
 * uso en la webapp.
 * @param {[]} bitacoras 
 */
const categorizeBitacoras = (bitacoras) => {
    let processedBitacoras = {
        actividades: [],
        producto: [],
        enfermedad: [],
        sintoma: [],
        cosecha: []
    };

    bitacoras.forEach(element => {
        let newRegistry = {
            id: element.id,
            type: element.tipo_bitacora,
            initDate: moment(element.fecha_inicio ? element.fecha_inicio : element.created_at).format('YYYY-MM-DD H:M:S'),
            name: element.descripcion.nombre_tipo ? element.descripcion.nombre_tipo : element.descripcion.nombre,
            timeLapse: moment(
                element.fecha_inicio ? element.fecha_inicio : (
                    element.created_at ? element.created_at : element.fecha_final
                )
                ).fromNow(),
            user: {
                name: element.descripcion.nombre_usuario,
                phone: element.users_telefono,
                email: element.users_email,
                image: element.avatar_curl.curl,
            }
        }
        if (element.fecha_final !== null) newRegistry.endDate = moment(element.fecha_final).format('YYYY-MM-DD H:M:S');
        if (element.descripcion.cantidad !== null) {
            newRegistry.quantity = element.descripcion.cantidad;
            switch (element.tipo_bitacora) {
                case 'Aplicacion Producto':
                    newRegistry.quantity = newRegistry.quantity + 'LTR';
                    break;
                case 'Cosecha':
                    newRegistry.quantity = newRegistry.quantity + 'KG';
                    break;
                default:
                    break;
            }
        }
        switch (element.tipo_bitacora) {
            case 'Aplicacion Producto':
                processedBitacoras.producto.push(newRegistry);
                break;
            case 'Enfermedad':
                processedBitacoras.enfermedad.push(newRegistry);
                break;
            case 'Sintomas':
                processedBitacoras.sintoma.push(newRegistry);
                break;
            case 'Cosecha':
                processedBitacoras.cosecha.push(newRegistry);
                break;
            case 'Actividad':
                processedBitacoras.actividades.push(newRegistry);
                break;
            default:
                break;
        }
    });
    return processedBitacoras;
}

export default categorizeBitacoras;