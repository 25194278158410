import React from 'react';
import { useStateValue } from '../../../state';
import { Marker, Polygon } from '@react-google-maps/api';


/**
 * Manejo marcadores y poligonos del mapa.
 */
const useMapPainter = () => {

    // eslint-disable-next-line
    const [{ }, dispatch] = useStateValue();

    
    /**
     * Crea un marcador con los datos dados. 
     * Si el marcador es único lo guarda en el estado global y regresará true,
     * de otra manera simplemente regresará el marcador creado.
     * @param {String} img - Imagen personalizada del marcador.
     * @param {String} lat - Coordenada de la latitud.
     * @param {String} lng - Coordenada de la longitud.
     * @param {Boolean} unique - Dado a que sea verdadero, será el único marcador en el estado.
     * @param {Function} onClickFn - Función que se ejecutara al realizar el click del marcador.
     * @returns {(Marker|Boolean)} - True si es único, o el componente de marcador si no es único.
     */
    const paintMarker = ( img, lat, lng, unique, onClickFn ) => {
        
        let newMarker = [];
    
        if (onClickFn !== undefined) {
            newMarker.push(
                <Marker
                icon={img}
                key={Math.random()}
                clickable={true}
                onClick= {() => { onClickFn() }}
                position={{
                    lat: lat,
                    lng: lng,
                    }}
                />
            );
        } else {
            newMarker.push(
                <Marker
                icon={img}
                key={Math.random()}
                clickable={false}
                position={{
                    lat: lat,
                    lng: lng,
                    }}
                />
            );
        }
    
        if (unique === true) {
            dispatch({
                type:'setMapMarkers',
                newMarkers: newMarker
            });
            return true;
        } else {
            return newMarker;
        }
    
    }
    
    /**
     * Crea un polígono con los datos dados. 
     * Si el polígono es único lo guarda en el estado global y regresará true,
     * de otra manera simplemente regresará el polígono creado.
     * @param {String} stroke - HEX de las aristas del polígono
     * @param {String} fill  - HEX del área del polígono 
     * @param {Array} coords - Coordenadas del polígono
     * @param {Boolean} unique  - Dado a que sea verdadero, será el único polígono en el estado.
     * @returns {(Polygon|Boolean)} - True si es único, o el componente del polígono si no es único.
     */
    const paintPolygon = (stroke, fill, coords, unique) => {
       
        let newPolygon = [
            <Polygon
            visible
            path={coords}
            key={Math.random()}
            options={{
                fillColor: fill,
                strokeWeight: 2,
                fillOpacity: 0.35,
                strokeOpacity: 1.0,
                strokeColor: stroke,
            }}/> 
        ];

        if (unique === true) {
            dispatch({
                type:'setMapPolygons',
                newPolygons: newPolygon
            });
            return true;
        } else {
            return newPolygon;
        }
    }

    return [
        paintMarker, 
        paintPolygon,
    ];

}

export default useMapPainter;