import React, {useState} from 'react';
import {useFetch} from 'use-http'
import { ERROR_GLOBAL_PASSWORD_MATCH, ERROR_RESET_PASSWORD_TOKEN } from '../common/consts';
import ResetComponent from './ResetComponent'
import ls from 'local-storage'

const ResetContainer = props => {
    
    const [errors, setErrors] = useState();
    const [sended, setSended] = useState(false);

    const req = useFetch({
        headers: {
            'Authorization' : props.token
        }
    });
    
    ls.remove('auth');   

    const onSubmit = formFields => {
        if (formFields.password === formFields.passwordConfirm) {

            req.patch('/reset',{
                password: formFields.password
            }).then( res => {
                if(res.AuthError){
                    setErrors(ERROR_RESET_PASSWORD_TOKEN)
                } else {
                    switch (res.respuesta_id) {
                        case "00":
                            setSended(true);
                            break;
    
                        default:
                            setErrors(res.mensaje);
                            break;
                    }
                }
            })

        } else {

            setErrors(ERROR_GLOBAL_PASSWORD_MATCH);

        }
    }

    if (props.token === undefined || props.token === null) {
        console.log("403! No sé puede pasar!");
    }

    return (
        <ResetComponent onSubmit={onSubmit} errors={errors} sended={sended} load={req.loading}/>
    )
 }

 export default ResetContainer