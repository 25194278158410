import React, {useState} from 'react';
import {useFetch} from 'use-http'
import { ERROR_GLOBAL_UNDEFINED } from '../common/consts';
import ForgotComponent from './ForgotComponent';
import ls from 'local-storage'

const ForgotContainer = props => { 

    const [errors, setErrors] = useState();
    const [sended, setSended] = useState(false);

    const req = useFetch();

    ls.remove('auth');   

    const onSubmit = formFields => {
        req.post('/forgot',{

            email: formFields.email,

        }).then( res => {
            if (res  === undefined) {
                setErrors(ERROR_GLOBAL_UNDEFINED);
            } else {
                switch (res.respuesta_id) {
                    case "00":
                        setSended(true);
                        setErrors();
                        break;
    
                    case "03":
                        setErrors(res.mensaje);
                        break;
    
                    case "06":
                        setErrors(res.mensaje);
                        break;
                
                    default:
                        setErrors(ERROR_GLOBAL_UNDEFINED);
                        break;
                }
            }
        });
    }
    
    return (
        <ForgotComponent onSubmit={onSubmit} errors={errors} sended={sended} setSended={setSended} load={req.loading}/>
    )

}

export default ForgotContainer