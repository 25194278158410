import ls from 'local-storage';
import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import { useStateValue } from '../../state';
import HomeComponent from './HomeComponent';

const HomeContainer = props => {

    const [mapMessage, setMapMessage] = useState('Interactuar con el mapa')
    const [{ appFocusRight, appFocusLeft }, dispatch] = useStateValue();

    let itFailed = false;

    const toggleFocusMap = () => {
        if (appFocusRight !== 'map' && appFocusLeft !== 'map') {
            document.getElementById('biobot-map').style.zIndex = 1;
            dispatch({
                type: 'setAppFocusRight',
                newAppFocusRight: 'map'
            });
            dispatch({
                type: 'setAppFocusLeft',
                newAppFocusLeft: 'map'
            });
            setMapMessage('Volver a la vista original');
        } else {
            document.getElementById('biobot-map').style.zIndex = 0;
            dispatch({
                type: 'setAppFocusRight',
                newAppFocusRight: null
            });
            dispatch({
                type: 'setAppFocusLeft',
                newAppFocusLeft: 'predios'
            });
            setMapMessage('Interactuar con el mapa');
        }
    }

    try {
        jwtDecode(ls.get('auth'));
    } catch {
        itFailed = true;
    }

    if (itFailed) {
        return (
            <Redirect noThrow to="/login" />
        );
    } else {
        return (
            <HomeComponent
            mapMessage={mapMessage}
            toggleFocusMap={toggleFocusMap}  />
        );
    }

}

export default HomeContainer;