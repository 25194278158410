import React from 'react';
import { useStateValue } from '../../state';
import DispositivosComponent from './DispositivosComponent';

const DispositivosContainer = props => {

    // eslint-disable-next-line
    const [{}, dispatch] = useStateValue();

    const onClickHandler = dispositivo => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'dispositivo'
        });
        dispatch({
            type: 'setDispositivoActual',
            newDispositivoActual: dispositivo
        });
    }

    const onReturnHandler = () => {
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: 'newsfeed'
        });
        dispatch({
            type: 'setDispositivoActual',
            newDispositivoActual: null
        });
    }

    return (
        <DispositivosComponent
        onClick={onClickHandler}
        onReturn={onReturnHandler} 
        dispositivos={props.dispositivos}/>
    )
} 

export default DispositivosContainer;
