import '../common/css/intelligence.css';

import TableCardRow from './TableCardRow';
import DatePicker from 'react-date-picker';
import React, { useState, useEffect } from 'react';
import { Card, Button, Image, Grid, Dropdown, Pagination } from 'semantic-ui-react';

const IntelligenceComponent = props => {
    
    // eslint-disable-next-line
    const [tableData, setTableData] = useState([]);
    const [filterTags, setFilterTags] = useState([]);

    const makeTableCards = () => {

        let tableCards = [];

        if (props.data !== [] || props.data.length !== 0) {
            props.data.forEach(element => {

                let documentLinks = [];
    
                element.docs.forEach((doc, index) => {
                    let currentIndex = index + 1;
                    
                    documentLinks.push(
                        <a href={doc} target='_blank' rel='noopener noreferrer' key={Math.random()}>{currentIndex === element.docs.length ? ('Archivo ' + currentIndex) : ('Archivo ' + currentIndex + ', ')}</a>
                    );
                });
    
                tableCards.push(
                    <TableCardRow
                        key={Math.random()}
                        date={element.date}
                        user={element.user}
                        mail={element.mail}
                        crop={element.crop}
                        city={element.city}
                        notes={element.notes}
                        state={element.state}
                        documents={documentLinks}
                        duration={element.duration}
                        registry={element.registry} />
                );
            });
    
            setTableData(tableCards);
        } 
    }

    const generateFilterTags = () => {
        let data = [];
        
        if(props.activeFilters.user) {
            data.push(
                <a className="ui label" href='#'>{props.activeFilters.user}
                    <i aria-hidden="true" class="delete icon" as='button' onClick={props.resetUserFilter()}></i>
                </a>
            );
        }
        if(props.activeFilters.type) {
            data.push(
                <a className="ui label" href='#'>{props.activeFilters.type}
                    <i aria-hidden="true" class="delete icon" as='button' onClick={props.resetTypeFilter()}></i>
                </a>
            );
        }
        if(props.activeFilters.crop) {
            data.push(
                <a className="ui label" href='#'>{props.activeFilters.crop}
                    <i aria-hidden="true" class="delete icon" as='button' onClick={props.resetCropFilter()}></i>
                </a>
            );
        }
        if(props.activeFilters.state) {
            data.push(
                <a className="ui label" href='#'>{props.activeFilters.state}
                    <i aria-hidden="true" class="delete icon" as='button' onClick={props.resetStateFilter()}></i>
                </a>
            );
        }
        if(props.activeFilters.city) {
            data.push(
                <a className="ui label" href='#'>{props.activeFilters.city}
                    <i aria-hidden="true" class="delete icon" as='button' onClick={props.resetCityFilter()}></i>
                </a>
            );
        }

        setFilterTags(data);
    };

    useEffect(() => {
        if(typeof props.data !== 'undefined') {
            makeTableCards();
        }
        return () => {
            setTableData([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    useEffect(() => {
        if(props.activeFilters !== null) {
            generateFilterTags();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeFilters]);

    return (
        <div className='h-100'>
            <div className='return-btn'>
                <Image src='/img/inteligencia/arrow-left.svg' className='dib' as='button' onClick={props.selectReturn()}></Image>
                <p className='dib charcoal-grey'>Regresar</p> 
            </div>
            <div id='title' className='dib w-100 mb2'>
                <h1 className="mt3 tc f1 charcoal-grey">Reporte de bitácora</h1>
            </div>
            <div className="w-100 tc relative mb5"> 
                <div id='date' className='dib mt3 tc'>
                    <span className='fw5'>Mostrando datos del:</span>
                    <Card className='center bg-light-gray bsn mt2'>
                        <Grid columns='2'>
                            <Grid.Row>
                                <Grid.Column>
                                    <DatePicker
                                        clearIcon={null}
                                        calendarIcon={null}
                                        value={props.fromDate}
                                        className='date-picker-light-gray'
                                        onChange={props.onChangeFromDate()} />
                                </Grid.Column>
                                <Grid.Column>
                                    <DatePicker
                                        clearIcon={null}
                                        calendarIcon={null}
                                        value={props.toDate}
                                        className='date-picker-light-gray'
                                        onChange={props.onChangeToDate()} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card>
                </div>
                <div id='export' className='dib absolute bottom-0 right-0 mr4'>
                    <Button onClick={() => { props.onExportToExcel(props.data) }} className='br18px bg-green'>
                        <Image inline className='dib mr3' src='/img/download.svg' />
                        <span>Exportar a Excel</span>
                    </Button>
                </div>
                <div id='export' className='dib absolute bottom-0 left-0 ml4'>
                    {filterTags}
                </div>
            </div>
            <div className='pre ml3 mr4 dib w-100'>
            {/* TABLA */}
                <div className='table-wrapper w-100'>
                    <table className='report-table'>
                        <thead>
                            <tr className='tl'>
                                <th>FECHA</th>
                                <th>DURACIÓN</th>
                                <th>
                                    {props.totalPages === 0 
                                    ?
                                        <Dropdown text='USUARIO' options={props.dropUsers} className='dropdown-intelligence disabled'
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                            }/>
                                    :
                                        <Dropdown search text='USUARIO' options={props.dropUsers} className='dropdown-intelligence'
                                            onChange={props.onChangeUser()}
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    }
                                </th>
                                <th>CORREO</th>
                                <th>
                                    { props.totalPages === 0 
                                    ?
                                        <Dropdown text='TIPO' options={props.dropTypes} className='dropdown-intelligence isType plr0 disabled'
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    :
                                        <Dropdown search text='TIPO' options={props.dropTypes} className='dropdown-intelligence isType plr0'
                                            onChange={props.onChangeType()}
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    } 
                                </th>
                                <th>NOTAS</th>
                                <th>ARCHIVOS ADJUNTOS</th>
                                <th>
                                    { props.totalPages === 0 
                                    ?
                                        <Dropdown text='CULTIVO' options={props.dropCrops} className='dropdown-intelligence disabled'
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    :
                                        <Dropdown search text='CULTIVO' options={props.dropCrops} className='dropdown-intelligence'
                                            onChange={props.onChangeCrop()}
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    }
                                </th>
                                <th>
                                    { props.totalPages === 0
                                    ?
                                        <Dropdown text='ESTADO' options={props.dropState} className='dropdown-intelligence disabled'
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/> 
                                    :
                                        <Dropdown search text='ESTADO' options={props.dropState} className='dropdown-intelligence'
                                            onChange={props.onChangeState()}
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                            }
                                        />  
                                    }
                                </th>
                                <th>
                                    { props.totalPages === 0
                                    ?
                                        <Dropdown text='MUNICIPIO' className='dropdown-intelligence disabled'
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    :
                                        <Dropdown search text='MUNICIPIO' options={props.dropCity} className='dropdown-intelligence'
                                            onChange={props.onChangeCity()}
                                            icon={
                                                <Image src='/img/chevron-green-down.svg' />
                                        }/>
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData}
                        </tbody>
                    </table>
                </div>
            </div>
            { props.totalPages === 0 || tableData.length === []
            ?
                <div className="report-placeholder">
                    <img src="/img/inteligencia/grupo-3835.svg" alt=""/>
                    <h3>No existen registros :( </h3>
                    <p>Por favor, inténtalo nuevamente</p>
                </div>
            :
                <div className='flex-center mt4 mb3'>
                    <Pagination
                        boundaryRange={1}
                        ellipsisItem={null}
                        siblingRange={3}
                        firstItem={1}
                        totalPages={props.totalPages}
                        activePage={props.currentPage}
                        onPageChange={props.onChangeCurrentPage()}
                        lastItem={typeof props.totalPages === 'undefined' ? 0 : props.totalPages}
                    />  
                </div>
            }
        </div>
    );
}

export default IntelligenceComponent;