import React from 'react';
import DatePicker from 'react-date-picker'
import bitacorasCards from '../bitacorasCards';
import { Card, Grid, Image } from 'semantic-ui-react';

const BitacoraListComponent = props => {

    let cartasBitacoras = [];    
    let emptyCounter = 0;

    cartasBitacoras.push(bitacorasCards('cosecha', props, true));
    cartasBitacoras.push(bitacorasCards('sintoma', props, true));
    cartasBitacoras.push(bitacorasCards('producto', props, true));
    cartasBitacoras.push(bitacorasCards('enfermedad', props, true));
    cartasBitacoras.push(bitacorasCards('actividades', props, true));

    cartasBitacoras.forEach( element => {
        if (element.length === 0) emptyCounter++;
    });

    return (
        <div>
            {
                !props.empty ? (
                    <div>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <span
                                        className="white">
                                        Desde
                                    </span>
                                    <DatePicker
                                        clearIcon={null}
                                        calendarIcon={null}
                                        value={props.today}
                                        className='date-picker'
                                        onChange={props.onChangeToday}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <span
                                        className="white">
                                        Hasta
                                    </span>
                                    <DatePicker
                                    clearIcon={null}
                                    calendarIcon={null}
                                    className='date-picker'
                                    value={props.aWeekFromNow}
                                    onChange={props.onChangeAWeekFromNow}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Card
                            className="center pa1 pt2 pb1"
                            fluid
                        >
                            { emptyCounter === 5 ? (
                                <div
                                    className='bitacoras-list tc'>
                                    <Image
                                    size='small'
                                    className='center mt3 mb3'
                                    src='/img/placeholder/bitacoras.svg' />
                                    <span
                                    className='fw6 f4 dib'>
                                        No tienes registros
                                    </span>
                                    <span
                                    className='dib mb3'>
                                        Intenta introduciendo otra fecha
                                    </span>
                                </div>
                            ) : (
                                cartasBitacoras
                            )}
                        </Card>
                    </div>
                )
                    : null
            }
        </div>
    );

}

export default BitacoraListComponent;