import React, {useState, useEffect, useRef} from 'react';
import {useFetch} from 'use-http';
import jwtDecode from 'jwt-decode';
import ls from 'local-storage';
import { Redirect } from '@reach/router';
import AcceptUserInvitationComponent from './AcceptUserInvitationComponent';
import { ERROR_GLOBAL_UNDEFINED } from '../../common/consts';

const AcceptUserInvitationContainer = props => {
  
  let tokenDecoded = {}
  let itFailed = false;

  const [errors, setErrors] = useState();
  const [sended, setSended] = useState(false); 
  
  ls.remove('auth');
  const req = useFetch({
      headers: {
          'Authorization' : props.token
      }
  });

  try {
    tokenDecoded = jwtDecode(props.token);
  } catch {
    itFailed = true;
  }

  // eslint-disable-next-line
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) return
    mounted.current= true
    if (!itFailed) {
      let data = new FormData()
      data.set('predio_id', tokenDecoded.predio_id);
      acceptInvitation(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const acceptInvitation = data => {
      req.post('/accept_invitation_user',data).then( res => {
        if (res  === undefined) {
            setErrors(ERROR_GLOBAL_UNDEFINED);
        } else if (res.respuesta_id && res.respuesta_id !== '00') {
            setErrors(res.mensaje);
        } else {
            setSended(true);
        }
    });
  }


  if (itFailed) {

    return (
      <Redirect noThrow to="/" />
    );

  } else {

    return (
        <AcceptUserInvitationComponent errors={errors} sended={sended} load={req.loading}/>
    );

  }

}

export default AcceptUserInvitationContainer;