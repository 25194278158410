import ls from 'local-storage';
import { useFetch } from 'use-http';
import React, {useState} from 'react';
import { navigate } from '@reach/router';
import { useStateValue } from '../../state';
import LoginComponent from './LoginComponent';
import { ERROR_GLOBAL_UNDEFINED, ERROR_LOGIN_INVALID } from '../common/consts';

const LoginContainer = props => {

    const [errors, setErrors] = useState();
    // eslint-disable-next-line
    const [{ predioActual } ,dispatch] = useStateValue();

    const req = useFetch();

    if (ls.get('auth')) {
        navigate('/home')
    }

    const emptyGlobalSatate = () => {
        dispatch({
            type: 'setPredioActual',
            newPredio: null
        });
        dispatch({
            type: 'setCultivoActual',
            newCultivo: null
        });
        dispatch({
            type: 'setBitacoraActual',
            newBitacoraActual: null
        });
        dispatch({
            type: 'setDispositivoActual',
            newDispositivoActual: null
        });
        dispatch({
            type: 'setSensorActual',
            newSensorActual: null
        });
        dispatch({
            type: 'setAppFocusRight',
            newAppFocusRight: null
        });
    }
 
    const onSubmit = formFields => {
        req.post('/auth',{
            email: formFields.email,
            password: formFields.password
        }).then( res => {
            if (res  === undefined) {
                setErrors(ERROR_GLOBAL_UNDEFINED);
            } else if (!res.AuthError && res.respuesta_id !== '04') {
                emptyGlobalSatate()
                ls.set('auth', res.token)
                navigate(`/home`)
            } else {
                setErrors(ERROR_LOGIN_INVALID);
            }
        })
    }

    return (
        <LoginComponent onSubmit={onSubmit} errors={errors}/>
    )
}

export default LoginContainer;