import React, { useState, useEffect } from 'react';
import 'moment/locale/es';
import moment from 'moment'
import ls from 'local-storage';
import useFetch from 'use-http';

import { useStateValue } from '../../../state';
import AnalysisComponent from './AnalysisComponent';
import ErrorContainer from '../../errors/ErrorContainer';
import LoadingContainer from '../../loading/LoadingContainer';

const AnalysisContainer = props => {

    // eslint-disable-next-line no-unused-vars
    const [{ inteligenciaFocus }, dispatch] = useStateValue();

    const [request, response] = useFetch({ headers: { 'Authorization': ls.get('auth')}});

    const [cardsInfo, setCardsInfo] = useState([]);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);

    const [user, setUser] = useState(null);
    const [filesId, setFilesId] = useState(null);
    const [filesInfo, setFilesInfo] = useState([]);

    const [selectedPredio, setSelectedPredio] = useState();
    const [expeditionDate, setExpeditionDate] = useState(new Date());

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [modalStatus, setModalStatus] = useState(false);

    const getCropsData = async() => {
        let query = '/business/slave/crops?pagina=' + currentPage + '&por_pagina=4';

        const dataAPI = await request.get(query);
        if(response.ok) {
            setTotalPages(dataAPI.total_paginas);

            let crop;
            let data = [];

            dataAPI.resultado.forEach(element => {
                crop = {
                    'id': element.id,
                    'nombre_predio': element.nombre,
                    'nombre_persona': element.name,
                    'map_curl': element.map_curl,
                };
                data.push(crop);
            });

            setCardsInfo(data);
        }
    }

    const getFilesData = async() => {
        if(filesId) {
            let query = '/analysis/' + filesId;

            const dataAPI = await request.get(query);
            if(response.ok) {
                let file;
                let data = [];

                dataAPI.forEach(element => {
                    let formattedDate = obtainDate(element.name);

                    file = {
                        dayAndMonth: formattedDate.dayAndMonth,
                        year: formattedDate.year,
                        curl: element.curl
                    };
                    data.push(file);
                });
                setFilesInfo(data);
            }
        }
    }

    const obtainDate = (url) => {
        let date = url.substr(url.length - 14);
        date = date.slice(0, -4);

        let year = date.slice(0, -6);

        let momentDate = moment(date).format('LL');
        let dayAndMonth = momentDate.slice(0, -8);

        let format = {
            dayAndMonth: dayAndMonth,
            year: year
        }

        return(format);
    }

    const onCropSelection = (event, data) => {
        setUser(data.value.user);
        setFilesId(data.value.id);
        setSelectedPredio(data.value.predio);
    }

    const onPageChange = (event, paginator) => {
        setCurrentPage(paginator.activePage);
    };

    const onModalStatusChange = (event, data) => {
        setUploadStatus(null);
        setModalStatus(data.value);
    };

    const uploadDocumentHandler = (event) => {
        event.preventDefault()
        let query = '/upload_analysis/' + filesId;

        var formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('fecha_expedicion', escape(moment(expeditionDate).format('YYYY-MM-DD')));
        fetch(query, {
                method: 'POST',
                headers: new Headers ({
                    'Authorization': ls.get('auth')
                }),
                body: formData
        }).then(res => {
            if(res  === undefined || res.status !== 200) { setUploadStatus(false); }
            else { setUploadStatus(true); }
        });
    }

    const changeExpeditionDateHandler = (date) => {
        setExpeditionDate(date);
    }

    const returnHandler = () => {
        dispatch({
            type: 'setInteligenciaFocus',
            newInteligenciaFocus: null
        });
    };

    useEffect(() => {
        getCropsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>  {
        getCropsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() =>  {
        getFilesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesId, uploadStatus]);

    return( 
        <>
            {request.error && <ErrorContainer error={request.error}/>}
            {request.loading && <LoadingContainer/>}
            {
                <AnalysisComponent
                    user = {user}
                    selectReturn = {() => returnHandler}
                    cardsInfo = {cardsInfo}
                    selectedPredio = {selectedPredio}
                    selectCrop = {() => onCropSelection}
                    filesInfo = {filesInfo}
                    modalStatus = {modalStatus}
                    changeModalStatus = {() => onModalStatusChange}
                    currentPage = {currentPage}
                    totalPages = {totalPages}
                    changePage = {() => onPageChange}
                    onChangeExpeditionDate = { changeExpeditionDateHandler }
                    expeditionDate = {expeditionDate}
                    onUploadDocument = {uploadDocumentHandler}
                    setFile = {setUploadedFile}
                    uploadedFile = {uploadedFile}
                    uploadStatus = {uploadStatus}
                />
            }
        </>
    );
}

export default AnalysisContainer;